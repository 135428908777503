import styled from 'styled-components';
import { RingDiv } from './Ring/styled';
import { WaveSVG } from './Wave/styled';
import { Link } from 'react-router-dom';

interface LayoutWrapperProps {
  isMobileReversed?: boolean;
  isOverflowEnabled?: boolean;
}

export const Content = styled.p<ContentProps>`
  width: 100%;
  white-space: pre-wrap;
  font-size: clamp(1rem, 1.2vw, 2rem);
  height: ${props => (props.height ? `${props.height}vh` : 'auto')};
  font-family: ProximaNova;
  padding: 0 2.5vw;
  margin: 0 0 2.5rem 0;
  text-align: justify;
  overflow-y: auto;
  max-height: 34vh;

  @media only screen and (max-width: 768px) {
    font-size: 1.45rem;
    max-height: 45vh;
  }
`;

export const LayoutWrapper = styled.section<LayoutWrapperProps>`
  width: 100vw;
  height: 100vh;
  background-color: grey;
  display: flex;
  overflow: hidden;
  position: relative;
  background-color: ${props => props.theme.colors.white};

  &.isLoading {
    & > * {
      filter: blur(8px) !important;
    }
  }

  ${WaveSVG} {
    margin: -0.5vh 0 0 -2.5vw;
    height: 101vh;
    z-index: 4;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: ${props =>
      props.isMobileReversed ? 'column-reverse' : 'column'};
    overflow: ${props => (props.isOverflowEnabled ? 'auto' : 'hidden')};
    height: ${props => (props.isOverflowEnabled ? 'auto' : '100vh')};
    padding: ${props => (props.isOverflowEnabled ? '20vh 0 0 0' : '0')};

    ${Content} {
      margin: ${props =>
        props.isOverflowEnabled ? '0 0 7.5rem 0' : '0 0 2.5rem 0'};
    }
  }
`;

export const ContentWrapper = styled.div<{ hasAdditionalSections: boolean }>`
  width: 46vw;
  overflow-y: auto;
  z-index: 9;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: calc(2vw + 10rem) 3vw calc(3vh + 7.25rem) 3vw;
  position: relative;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    justify-content: flex-start;
    padding-top: calc(2vw + 7rem);
    padding-bottom: 30px;
  }
`;

export const Header = styled.h1<{ isSmaller: boolean }>`
  width: 100%;
  font-size: ${props => (props.isSmaller ? '6rem' : 'clamp(2rem, 4vw, 8rem)')};
  letter-spacing: -2px;
  font-family: Rubik;
  white-space: pre-wrap;
  text-transform: uppercase;
  transform: scaleX(0.85);
  line-height: 1.2em;
  margin: 0 0 0 -1vw;
  animation-delay: 3s;
  animation: fade-in 1500ms 1;
  opacity: 0;
  animation-fill-mode: forwards;

  @media only screen and (max-width: 768px) {
    font-size: clamp(3rem, 2vw, 3.8rem) !important;
    transform: scaleX(1);
    width: auto;
    padding: 0 2.5vw;
    animation: fade-in-mobile 1500ms 1 !important;
    opacity: 1;
  }

  @keyframes fade-in {
    from {
      transform: translateX(-6vw) scaleX(0.85);
      opacity: 0;
    }

    to {
      transform: translateX(0vw) scaleX(0.85);
      opacity: 1;
    }
  }

  @keyframes fade-in-mobile {
    from {
      transform: translateX(-6vw);
      opacity: 0;
    }

    to {
      transform: translateX(0vw);
      opacity: 1;
    }
  }

  span {
    font-family: PermanentMarker;
    color: ${props => props.theme.colors.main};
    font-size: ${props => (props.isSmaller ? '1em' : '1.5em')};
    letter-spacing: 10px;
    margin: 10px 0 0 0;
  }
`;

export const SmallerHeader = styled.h3`
  font-size: 4rem;
  margin: 0 auto 0.5em 2.5vw;
  font-family: PermanentMarker;
  color: ${props => props.theme.colors.main};

  @media only screen and (max-width: 768px) {
    font-size: 3rem !important;
    margin: 0 auto 0.2em 2.5vw;
  }
`;

interface BackgroundWrapperProps {
  background: string;
}

export const BackgroundWrapper = styled.div<BackgroundWrapperProps>`
  width: 55vw;
  height: 100vh;
  background: url(${props => props.background});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 3;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
  }
`;

export const ActionWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;

  ${RingDiv} {
    position: fixed;

    &:first-of-type {
      width: 35vh;
      height: 35vh;
      animation-delay: 0s;
    }

    &:nth-of-type(2) {
      width: 55vh;
      height: 55vh;
      animation-delay: 0.5s;
    }

    &:nth-of-type(3) {
      width: 75vh;
      height: 75vh;
      animation-delay: 1s;
    }
  }
`;

export const ContentHeader = styled.h3`
  font-size: 4em;
  font-family: ProximaNova;
  margin: 5vh 0 1em;

  @media only screen and (max-width: 768px) {
    font-size: 3em;
    margin: 1.8em 0 0.7em;
  }
`;

interface ContentProps {
  height?: number;
}

export const Bold = styled.span`
  font-family: ProximaNovaBold;
`;

export const LogoLink = styled(Link)`
  position: absolute;
  top: 2vw;
  left: 5.5vw;
  z-index: 1001;
`;

export const LayoutLogo = styled.img`
  width: 8rem;
  height: 8rem;

  @media only screen and (max-width: 768px) {
    width: 5rem;
    height: 5rem;
  }
`;

export const Timer = styled.h3`
  font-size: 6em;
  font-family: ProximaNova;

  @media only screen and (max-width: 768px) {
    font-size: 5em;
    margin: -1.8em 0 2.5em;
  }
`;

export const LoginLink = styled.div`
  font-size: 0.9em;
  font-family: ProximaNova;

  span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #551a8b;
    }
  }
`;
