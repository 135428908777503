import styled from 'styled-components';

export const UsersWrapper = styled.div`
  width: 95.2%;
  height: 86%;
  margin: 0 auto;
  overflow-y: auto;
  padding: 0 0 3vh;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 0 3vh;
  }
`;
