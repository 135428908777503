import Layout from 'components/Layout';
import { Content, ContentHeader } from 'components/Layout/styled';
import React from 'react';
import ImageGallery from 'react-image-gallery';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import history from 'utils/history';

import bg from '../../assets/landing/landingBg.jpg';
import img1 from 'assets/gallery/1.jpg';
import img2 from 'assets/gallery/2.jpg';
import img3 from 'assets/gallery/3.jpg';
import img4 from 'assets/gallery/4.jpg';
import img5 from 'assets/gallery/5.jpg';
import img6 from 'assets/gallery/6.jpg';
import img7 from 'assets/gallery/7.jpg';
import img8 from 'assets/gallery/8.jpg';
import img9 from 'assets/gallery/9.jpg';
import img10 from 'assets/gallery/10.jpg';
import img11 from 'assets/gallery/11.jpg';
import img12 from 'assets/gallery/12.jpg';

import 'react-image-gallery/styles/css/image-gallery.css';

export const Gallery = () => {
  const images = [
    {
      original: img1,
      thumbnail: img1,
    },
    {
      original: img2,
      thumbnail: img2,
    },
    {
      original: img3,
      thumbnail: img3,
    },
    {
      original: img4,
      thumbnail: img4,
    },
    {
      original: img5,
      thumbnail: img5,
    },
    {
      original: img6,
      thumbnail: img6,
    },
    {
      original: img7,
      thumbnail: img7,
    },
    {
      original: img8,
      thumbnail: img8,
    },
    {
      original: img9,
      thumbnail: img9,
    },
    {
      original: img10,
      thumbnail: img10,
    },
    {
      original: img11,
      thumbnail: img11,
    },
    {
      original: img12,
      thumbnail: img12,
    },
  ];

  const imageGallery = () => <ImageGallery items={images} />;

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="GALERIA"
      green={''}
      // nextLink={'/partners'}
      nextLink="/team"
      previousLink="/about"
      currentPageNumber={3}
      isCenterButtonHidden
      interactiveSection={imageGallery}
      buttonAction={() => history.push('/register')}
      isMobileReversed
    >
      <ContentHeader>Zobacz jak wyglądały poprzednie edycje</ContentHeader>
      <Content>
        Pierwszy Adapciak został zorganizowany w 2009 roku. Od tego czasu udało
        nam się zebrać wiele niesamowitych wspomnień.
      </Content>
    </Layout>
  );
};
