import { Filters as FiltersType, getUsersWithFilters } from 'api/panel';
import { Participant } from 'api/users';
import { useEffect, useState } from 'react';
import { UsersWrapper } from './styled';
import User from './User';
import Filters from './Filters';
import Summary, { CSVProps } from './Summary';

export const csvProps = [
  {
    value: 'name',
    title: 'Imię',
  },
  {
    value: 'surname',
    title: 'Nazwisko',
  },
  {
    value: 'phone',
    title: 'Telefon',
  },
  {
    value: 'bday',
    title: 'Data urodzenia',
  },
  {
    value: 'email',
    title: 'Email',
  },
  {
    value: 'degreeCourse',
    title: 'Kierunek studiów',
  },
  {
    value: 'faculty',
    title: 'Wydział',
  },
  {
    value: 'year',
    title: 'Rok studiów',
  },
  {
    value: 'diet',
    title: 'Dieta',
  },
  {
    value: 'hasPaid',
    title: 'Opłacone',
  },
  {
    value: 'isConfirmed',
    title: 'Potwierdzone',
  },
  {
    value: 'shoe',
    title: 'Rozmiar buta',
  },
  {
    value: 'tshirt',
    title: 'Rozmiar koszulki',
  },
];

const Users = () => {
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [fetchedParticipants, setFetchedParticipants] = useState<Participant[]>(
    [],
  );
  const [filters, setFilters] = useState<FiltersType[]>([]);
  const [search, setSearch] = useState<string>('');
  const [csvData, setCSVData] = useState<string[][]>();
  const [checkCSVprops, setCheckCSVprops] = useState<CSVProps[]>(csvProps);

  const reloadUsers = () => {
    getUsersWithFilters(filters, data => handleUsersData(data));
  };

  const handleUsersData = async (data: Participant[]) => {
    const sortedData = [...data].sort((a, b) => {
      return a.surname.localeCompare(b.surname);
    });

    const newCSVData: string[][] = [];
    newCSVData.push(checkCSVprops.map(p => p.title));

    sortedData.forEach(u => {
      newCSVData.push(
        checkCSVprops.map(p =>
          u[p.value as keyof Participant]
            ? (u[p.value as keyof Participant] as string)
            : '-',
        ),
      );
    });
    setCSVData(newCSVData);
    setParticipants(sortedData);
    setFetchedParticipants(sortedData);
  };

  const handleSearchChange = (value: string) => {
    if (value) {
      const data = [...fetchedParticipants];
      const filteredParticipants = data.filter(
        p =>
          p.name.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          p.surname.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          p.email.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          p.degreeCourse
            .toLocaleLowerCase()
            .includes(value.toLocaleLowerCase()) ||
          p.faculty.toLocaleLowerCase().includes(value.toLocaleLowerCase()) ||
          `${p.year.toString()} rok`.includes(value) ||
          p.color?.toLocaleLowerCase().includes(value.toLocaleLowerCase()),
      );
      setParticipants(filteredParticipants);
    } else {
      setParticipants(fetchedParticipants);
    }
    setSearch(value);
  };

  useEffect(() => {
    const removeAllSubscription = getUsersWithFilters(filters, data =>
      handleUsersData(data),
    );

    return () => {
      removeAllSubscription();
    };
  }, [filters]);

  const handleFilterClick = (value: FiltersType) => {
    const updatedFilters = [...filters];
    if (updatedFilters.includes(value)) {
      const index = updatedFilters.findIndex(v => v === value);
      updatedFilters.splice(index, 1);
    } else {
      updatedFilters.push(value);
    }
    setFilters(updatedFilters);
  };

  return (
    <>
      <Filters
        search={search}
        handleSearchInput={(v: string) => handleSearchChange(v)}
        handleFilterClick={(v: FiltersType) => handleFilterClick(v)}
      />
      <UsersWrapper>
        {participants &&
          participants.length > 0 &&
          participants.map((p, i) => {
            return <User index={i + 1} data={p} />;
          })}
      </UsersWrapper>
      <Summary
        csvData={csvData}
        resultsCount={participants?.length}
        checkCSVprops={checkCSVprops}
        setCheckCSVprops={setCheckCSVprops}
        reload={reloadUsers}
      />
    </>
  );
};

export default Users;
