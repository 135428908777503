import styled from 'styled-components';

export const FiltersWrapper = styled.div`
  width: calc(100% - 6vw);
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #22292f;
  height: 10vh;
  margin: 3vh auto 0 auto;
  color: ${props => props.theme.colors.white};
  font-family: ProximaNovaBold;
  font-size: 2rem;

  @media (max-width: 768px) {
    font-size: 1.3em;
    width: 100%;
  }
`;

export const FormData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18%;

  @media (max-width: 768px) {
    width: 25%;
  }

  &:last-of-type {
    width: 30%;
  }
`;

export const Checkmark = styled.span`
  margin: 0 0 0 1vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
  background-color: #eee;

  @media (max-width: 768px) {
    width: 15px;
    height: 15px;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }
`;

export const Container = styled.label`
  position: relative;
  height: 25px;
  width: 10%;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    padding: 0;
    margin: 0;
  }

  &:hover input ~ ${Checkmark} {
    background-color: #ccc;
  }

  & input:checked ~ ${Checkmark} {
    background-color: ${props => props.theme.colors.main};
  }

  & input:checked ~ ${Checkmark}:after {
    display: block;
  }

  & ${Checkmark}:after {
    left: 12px;
    top: 5px;
    width: 5px;
    height: 15px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media (max-width: 768px) {
      left: 8px;
      top: 0px;
      width: 3px;
    }
  }
`;

export const SearchIput = styled.input`
  width: 70%;
  font-size: 1.1em;
  font-family: ProximaNovaBold;
  border-radius: 20px;
  margin: 0 0 0 0.5em;
  padding: 0.2em 0.45em;
  border: 2px solid ${props => props.theme.colors.main};

  @media (max-width: 768px) {
    font-size: 1.2em;
  }
`;
