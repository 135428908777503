import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

export const selectGlobalDomain = (state: ApplicationRootState) =>
  state.global || initialState;

const makeSelectGlobal = () =>
  createSelector(selectGlobalDomain, substate => substate);

export const makeSelectIsSidebarMenuOpen = () =>
  createSelector(selectGlobalDomain, substate => substate.isSidebarMenuOpen);

export const makeSelectIsLoading = () =>
  createSelector(selectGlobalDomain, substate => substate.isLoading);

export const makeSelectCurrentUser = () =>
  createSelector(selectGlobalDomain, substate => substate.currentUser);

export default makeSelectGlobal;
