import Layout from 'components/Layout';
import { Bold, Content, ContentHeader } from 'components/Layout/styled';
import history from 'utils/history';

import bg from '../../assets/account/20.jpg';
import { GiPayMoney as MoneyIcon } from 'react-icons/gi';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Avatar, Input } from './styled';

import { setLoading, participantLoginSuccess } from 'containers/App/actions';
import { Participant, uploadAvatar } from 'api/users';
import { useState } from 'react';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const FILE_SIZE_LIMIT = 4194304;

export const Account = () => {
  const { currentUser } = useSelector(stateSelector);
  const [warning, setWarning] = useState<string>();
  const dispatch = useDispatch();

  const handleFileAddition = async (file: File) => {
    dispatch(setLoading(true));
    if (file.size > FILE_SIZE_LIMIT) {
      setWarning('Maksymalny dopuszczalny rozmiar pliku to 4MB');
    } else {
      setWarning(undefined);
      if (file && !warning) {
        const url = await uploadAvatar(file);
        dispatch(
          participantLoginSuccess({
            ...currentUser,
            avatar: url,
          } as Participant),
        );
      }
      dispatch(setLoading(false));
    }
  };

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={MoneyIcon}
      header="TWOJE KONTO"
      green={''}
      nextLink="/"
      previousLink="/"
      currentPageNumber={999}
      isNavHidden
      buttonAction={() => history.push('/payment')}
    >
      <ContentHeader>Dane konta:</ContentHeader>
      <Avatar url={currentUser?.avatar}>
        <Input
          onChange={e =>
            e.target.files && handleFileAddition(e.target.files[0])
          }
          required
          placeholder="Potwierdzenie płatności"
          type="file"
          accept=".png,.webp,.jpg,.png,.jpeg"
        />
        {currentUser?.avatar ? '' : <span>prześlij avatara</span>}
      </Avatar>
      <Content>
        <Bold>Imię i nazwisko:</Bold> {currentUser?.name} {currentUser?.surname}
        <br />
        <Bold>Email:</Bold> {currentUser?.email}
        <br />
        <Bold>Wydział i kierunek:</Bold> {currentUser?.faculty}{' '}
        {currentUser?.degreeCourse}
        <br />
        <Bold>Rok studiów:</Bold> {currentUser?.year}
        <br />
        <Bold>Czy zapłacone:</Bold> {currentUser?.hasPaid ? 'Tak' : 'Nie'}
        <br />
        <Bold>Czy zakwalifikowano na wyjazd:</Bold>{' '}
        {currentUser?.isConfirmed ? 'Tak' : 'Nie'}
      </Content>
    </Layout>
  );
};
