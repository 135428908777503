import { auth } from 'api/firebase';
import { checkIfAdmin, getUserDetails } from 'api/users';
import AdminRoute from 'components/AdminRoute';
import Loader from 'components/Loader';
import ProtectedRoute from 'components/ProtectedRoute';
import {
  About,
  Account,
  Admin,
  AdminPanel,
  FAQ,
  Gallery,
  Landing,
  Login,
  Partners,
  PasswordReset,
  Payment,
  // ScoreboardPage,
  Team,
  Register,
} from 'containers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { useInjectReducer } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { GlobalStyle } from 'theme/global-styles';
import { participantLoginSuccess } from './actions';
import reducer from './reducer';
import { makeSelectIsLoading } from './selectors';

import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/swiper.min.css';

const stateSelector = createStructuredSelector({
  loading: makeSelectIsLoading(),
});

export function App() {
  useInjectReducer({ key: 'global', reducer: reducer });

  const dispatch = useDispatch();

  const { loading } = useSelector(stateSelector);

  useEffect(() => {
    auth.onAuthStateChanged(async user => {
      if (user) {
        const data = await getUserDetails(user.uid);
        const isAdmin = await checkIfAdmin(user.uid);
        dispatch(participantLoginSuccess({ ...data, isAdmin }));
      } else {
        console.log('Logged out');
      }
    });
  }, [dispatch]);

  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route exact path="/" component={Landing} />
        <Route exact path="/about" component={About} />
        <Route exact path="/admin" component={Admin} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/team" component={Team} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/gallery" component={Gallery} />
        <Route exact path="/partners" component={Partners} />
        {/* <Route path="/scoreboard" component={ScoreboardPage} /> */}
        <Route exact path="/faq" component={FAQ} />
        <Route exact path="/reset-password" component={PasswordReset} />
        <ProtectedRoute path="/payment" component={Payment} />
        <ProtectedRoute path="/account" component={Account} />
        <AdminRoute path="/admin-panel" component={AdminPanel} />
      </Switch>
      {loading && <Loader />}
    </>
  );
}
