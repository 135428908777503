import React from 'react';
import { TeamMemberType } from '../../types';
import { ItemWrapper, MemberPhoto, MemberRole } from './styled';

interface CarouselleItemProps {
  item: TeamMemberType;
  isActive: boolean;
}

const CarouselleItem: React.FC<CarouselleItemProps> = ({ item, isActive }) => {
  return (
    <ItemWrapper>
      {/* <MemberName isActive={isActive}>{item.name}</MemberName> */}
      <MemberPhoto src={item.img} alt={item.alt} />
      <MemberRole isActive={isActive}>{item.role}</MemberRole>
    </ItemWrapper>
  );
};

export default CarouselleItem;
