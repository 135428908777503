import Layout from 'components/Layout';
import { ContentHeader, Content, Bold } from 'components/Layout/styled';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import { GiPayMoney as MoneyIcon } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';

import bg from 'assets/about/about.jpg';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

export const About = () => {
  const { currentUser } = useSelector(stateSelector);

  return (
    <Layout
      background={bg}
      ButtonIcon={currentUser ? MoneyIcon : TicketIcon}
      header="O Adapciaku"
      nextLink="/gallery"
      previousLink="/"
      currentPageNumber={2}
      isHeaderSmaller
      buttonAction={() => history.push(currentUser ? '/payment' : '/register')}
    >
      <ContentHeader>Czym jest adapciak?</ContentHeader>
      <Content height={50}>
        <Bold>Adapciak Politechniki Łódzkiej</Bold> - coroczny Obóz Zerowy
        organizowany od 2009 roku przez Samorząd Studencki Politechniki
        Łódzkiej. Wyjazd ten skierowany jest dla nowo przyjętych na studia, jak
        i dla tych, którzy są już studentami Politechniki Łódzkiej. Tegoroczna
        edycja odbywa się w dniach 31-6 września 2024, w ośrodku „EGM-Łazy”, w
        nadmorskiej miejscowości Łazy położonej w okolicach Mielna. <br />
        <br /> Jest to wydarzenie, podczas którego blisko 180 studentów
        Politechniki Łódzkiej ma okazję poznać smak studenckiego życia tuż przed
        rozpoczęciem roku akademickiego. Głównym celem obozu jest przekazanie
        informacji i zasad dotyczących studiowania na Politechnice Łódzkiej,
        zachęcanie do czynnego udziału w organizacjach studenckich i kołach
        naukowych oraz integracja zarówno z nowymi studentami, jak i kadrą -
        osobami już studiującymi na Politechnice Łódzkiej, którzy chętnie
        podzielą się swoimi doświadczeniami związanymi z Uczelnią oraz zawsze
        będą służyć dobrą radą. <br />
        <br /> W czasie trwania Adapciaka, uczestnicy będą mieli możliwość
        wzięcia udziału w wielu szkoleniach, które rozwijają umiejętności i
        pomagają w realizacji życiowych planów. Przeprowadzane jest również
        szkolenie z Elementów Prawa w Szkolnictwie Wyższym, które przyczynia się
        do wprowadzenia przyszłych studentów w życie Uczelni.
      </Content>
    </Layout>
  );
};
