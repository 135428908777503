import { gql } from '@apollo/client';

export const CONTACT_MUTATION = gql`
  mutation CreateSubmissionMutation(
    $clientMutationId: String!
    $email: String!
    $sender: String!
    $topic: String!
    $message: String!
  ) {
    createSubmission(
      input: {
        clientMutationId: $clientMutationId
        email: $email
        sender: $sender
        topic: $topic
        message: $message
      }
    ) {
      success
      data
    }
  }
`;

export const MULTIPLE_CONTACT_MUTATION = gql`
  mutation CreateMultipleSubmissions(
    $clientMutationId: String!
    $emails: [String]!
    $sender: String!
    $topic: String!
    $message: String!
  ) {
    createMultipleSubmissions(
      input: {
        clientMutationId: $clientMutationId
        emails: $emails
        sender: $sender
        topic: $topic
        message: $message
      }
    ) {
      success
      data
    }
  }
`;
