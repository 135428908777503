import { TileWrapper, TileIcon, TileContent } from './styled';
import { IconType } from 'react-icons/lib';

interface TileProps {
  backgroundColor: string;
  color: string;
  Icon: IconType;
  title: string;
  text: string;
}

const Tile = ({ backgroundColor, color, Icon, title, text }: TileProps) => {
  return (
    <TileWrapper backgroundColor={backgroundColor} color={color}>
      <TileIcon color={color}>
        <div>
          <Icon />
        </div>
      </TileIcon>
      <TileContent>
        <p>{title}</p>
        <span>{text}</span>
      </TileContent>
    </TileWrapper>
  );
};

export default Tile;
