import { Participant } from 'api/users';
import { action } from 'typesafe-actions';
import { ActionTypes } from './constants';

export const resetState = () => action(ActionTypes.RESET_STATE, {});
export const toggleMenu = () => action(ActionTypes.TOGGLE_MENU, {});

export const setLoading = (isLoading: boolean) =>
  action(ActionTypes.SET_LOADING, { isLoading });

export const participantLoginSuccess = (user: Participant) =>
  action(ActionTypes.PARTICIPANT_LOGIN_SUCCESS, { user });

export const participantLogout = () =>
  action(ActionTypes.PARTICIPANT_LOGIN_SUCCESS, { user: null });

export const participantPaymentSuccess = () =>
  action(ActionTypes.PARTICIPANT_PAYMENT_SUCCESS, {});
