import styled from 'styled-components';

export const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  font-family: ProximaNovaBold;
  text-align: center;
`;

export const Input = styled.input`
  cursor: pointer;
  width: 50%;
  font-size: 1.5rem;
  text-align: center;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const Button = styled.button`
  cursor: pointer;
  width: 50%;
  font-size: 1.5rem;
  margin: 0.5rem;
`;
