import styled, { css } from 'styled-components';
import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';

export const MenuWrapper = styled.div<{ isActive: boolean }>`
  border-radius: 50%;
  position: fixed;
  top: -18vh;
  right: -18vh;
  width: 35vh;
  height: 35vh;
  background-color: white;
  z-index: 99;
  transition: all 0.4s;

  @media only screen and (max-width: 768px) {
    top: -18vh;
    left: 0px;
    right: unset;
    width: 100vw;
    height: 23vh;
    border-radius: unset;
  }

  /* z-index: ${props => (props.isActive ? '99' : 'unset')}; */
  transform: ${props => (props.isActive ? 'scale(3)' : 'unset')};
  box-shadow: ${props =>
    props.isActive ? '0px 0 20px -1px #00000080' : 'unset'};

  @media only screen and (max-width: 768px) {
    transform: ${props => (props.isActive ? 'scale(7)' : 'unset')};
  }
`;

const iconsCSS = css`
  position: fixed;
  top: 1.7vw;
  right: 1.7vw;
  font-size: clamp(2rem, 2.5vw, 4.5rem);
  color: black;
  z-index: 100;

  @media only screen and (max-width: 768px) {
    font-size: 5rem;
  }

  &:hover {
    color: ${props => props.theme.colors.main};
    cursor: pointer;
  }
`;

export const MenuIcon = styled(FiMenu)`
  ${iconsCSS}
`;

export const CloseIcon = styled(MdClose)`
  ${iconsCSS}
`;

export const MenuEntries = styled.nav<{ isActive: boolean }>`
  position: fixed;
  top: 1vw;
  right: 6vw;
  z-index: 1002;
  transition: all 0.3s;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transform: ${props =>
    props.isActive ? 'translate(0,0)' : 'translate(0,-50vh)'};

  @media only screen and (max-width: 768px) {
    right: unset;
    left: 50%;
    transform: ${props =>
      props.isActive ? 'translate(-50%,5vh)' : 'translate(0,-50vh)'};
  }
`;

export const EntriesList = styled.ul`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Entry = styled.li`
  font-size: clamp(1.2rem, 1.2vw, 2.3rem);
  font-family: ProximaNovaBold;
  margin: 0.3em 0;

  @media only screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  a {
    text-decoration: none;
    text-align: right;
    color: black;

    &:hover {
      cursor: pointer;
      color: ${props => props.theme.colors.main};
    }
  }
`;
