import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  background: #00000090;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 2000;
  left: 0;
  top: 0;

  & > svg {
    width: 100px;
    height: 100px;
    fill: white;
    z-index: 1000;

    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
