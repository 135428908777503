import Layout from 'components/Layout';
import Counter from 'components/Counter';
import { Bold, Content } from 'components/Layout/styled';
import { makeSelectCurrentUser } from 'containers/App/selectors';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import { GiPayMoney as MoneyIcon } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';

import bg from 'assets/landing/landingBg.jpg';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

export const Landing = () => {
  const { currentUser } = useSelector(stateSelector);

  return (
    <Layout
      background={bg}
      smallerHeader="Łazy"
      ButtonIcon={currentUser ? MoneyIcon : TicketIcon}
      header="Obóz Adapciak "
      green={new Date().getFullYear().toString()}
      nextLink="/about"
      // previousLink="/team"
      previousLink="/partners"
      currentPageNumber={1}
      buttonAction={() => history.push(currentUser ? '/payment' : '/register')}
    >
      <Counter />
      <Content>
        <Bold>Adapciak</Bold> to obóz integracyjno-szkoleniowy dla przyszłych
        studentów Politechniki Łódzkiej. Świetna zabawa, mnóstwo atrakcji, nowe
        znajomości, warsztaty i szkolenia – to właśnie najlepszy wyjazd
        studencki, na którym nie może Cię zabraknąć!
      </Content>
    </Layout>
  );
};
