import { auth, decrement, firestore, increment, storage } from './firebase';
import { Statistics } from './panel';

import history from 'utils/history';

export type InvoiceData = {
  name: string;
  surname: string;
  address: string;
  pesel: string;
};

interface PaymentInfo {
  file: File;
  phone: string;
  year: number | undefined;
  diet: string;
  tshirt: string;
  shoe: string;
  bday: string;
  invoice?: InvoiceData;
}

export const uploadPaymentConfirmation = async ({
  file,
  phone,
  year,
  diet,
  tshirt,
  shoe,
  bday,
  invoice,
}: PaymentInfo) => {
  try {
    const uid = auth.currentUser!.uid;

    const stats = await getCurrentConfig();
    const expirationDate = localStorage.getItem('expirationDate');

    if (stats.remainingSlots > 0 && year) {
      if (new Date().getTime() < Number(expirationDate)) {
        const path = `payments/${uid}`;
        await storage.ref(path).put(file);
        const url = await storage.ref('payments').child(uid).getDownloadURL();
        let invoiceToUpdate = invoice ?? {};
        await firestore.collection('participants').doc(uid).update({
          hasPaid: true,
          modifiedAt: new Date(),
          paymentFile: url,
          phone,
          diet,
          tshirt,
          shoe,
          bday,
          invoiceToUpdate,
        });

        localStorage.setItem('payment', 'true');
      }
    }
  } catch (err) {
    throw new Error((err as any).message);
  }
};

const generateUpdateInstruction = (year: number, stats: Statistics) => {
  if (stats.remainingSlots > 0) {
    return {
      remainingSlots: decrement,
    };
  }
  return {};
};

const getCurrentConfig = async () => {
  const res = await firestore
    .collection('statistics')
    .doc(`config-${new Date().getFullYear()}`)
    .get();
  return res.data() as Statistics;
};

export const takeRemainingSlot = async () => {
  const isTaken = localStorage.getItem('expirationDate');

  if (!isTaken) {
    const stats = await getCurrentConfig();

    const expirationDate = Date.now() + 1200000; // 20 min
    localStorage.setItem('expirationDate', expirationDate.toString());

    const updateInstruction = generateUpdateInstruction(0, stats);

    if (updateInstruction) {
      await firestore
        .collection('statistics')
        .doc(`config-${new Date().getFullYear()}`)
        .update(updateInstruction);
    }
  }
};

export const restoreRemainingSlot = async () => {
  localStorage.removeItem('expirationDate');
  history.push('/');

  await firestore
    .collection('statistics')
    .doc(`config-${new Date().getFullYear()}`)
    .update({
      remainingSlots: increment,
    });
};
