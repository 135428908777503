import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import theme from './theme';

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  return (
    <StyledThemeProvider theme={theme}>
      {React.Children.only(props.children)}
    </StyledThemeProvider>
  );
};
