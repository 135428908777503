import { WaveSVG } from './styled';

const Wave = () => {
  return (
    <WaveSVG
      viewBox="0 0 37.742603 214.74544"
      version="1.1"
      id="svg5"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2" />
      <g id="layer1">
        <path
          style={{
            fill: '#ffffff',
            stroke: '#ffffff',
            strokeWidth: '0.264583px',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeOpacity: 1,
          }}
          d="m 28.941999,214.65313 c 0,0 20.15603,-42.55163 -0.86137,-109.39385 C 7.0632251,38.417054 28.425169,0.17227382 28.425169,0.17227382 H 0.17227502 V 214.65312 Z"
          id="path107"
        />
      </g>
    </WaveSVG>
  );
};

export default Wave;
