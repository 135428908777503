import styled from 'styled-components';

export const Avatar = styled.div<{ url: string | undefined }>`
  position: relative;
  width: 25vh;
  height: 25vh;
  background-color: ${props => props.theme.colors.main};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.white};
  font-family: ProximaNova;

  background-image: url(${props => props.url});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  span {
    font-size: clamp(2rem, 2vw, 3rem);
    text-align: center;
  }
  margin: 0 0 2vh 0;
`;

export const Input = styled.input`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0;

  &:hover {
    cursor: pointer;
  }
`;
