import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theme/ThemeProvider';
import history from 'utils/history';
import configureStore from './configureStore';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from '@apollo/client';
import client from 'apollo';

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

const Providers: React.FunctionComponent = ({ children }) => {
  return (
    <Provider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <React.StrictMode>
            <ConnectedRouter history={history}>{children}</ConnectedRouter>
          </React.StrictMode>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>
  );
};

export default Providers;
