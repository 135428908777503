import Menu from 'components/Menu';
import { IconType } from 'react-icons/lib';
import Button from './Button';
import Navigation from './Navigation';
import Ring from './Ring';
import logo from 'assets/layout/logo.png';
import {
  ActionWrapper,
  BackgroundWrapper,
  ContentWrapper,
  Header,
  LayoutLogo,
  LayoutWrapper,
  LogoLink,
  SmallerHeader,
} from './styled';
import Wave from './Wave';
import { makeSelectIsLoading } from 'containers/App/selectors';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import clsx from 'clsx';

interface LayoutProps {
  smallerHeader?: string;
  header?: string;
  green?: string;
  buttonAction: () => void;
  ButtonIcon: IconType;
  background: string;
  previousLink: string;
  nextLink: string;
  currentPageNumber: number;
  isHeaderSmaller?: boolean;
  interactiveSection?: () => JSX.Element;
  isNavHidden?: boolean;
  isCenterButtonHidden?: boolean;
  isMobileReversed?: boolean;
  isOverflowEnabled?: boolean;
}

const stateSelector = createStructuredSelector({
  isLoading: makeSelectIsLoading(),
});

const Layout: React.FC<LayoutProps> = ({
  children,
  smallerHeader,
  header,
  green,
  buttonAction,
  ButtonIcon,
  background,
  nextLink,
  previousLink,
  currentPageNumber,
  isHeaderSmaller,
  interactiveSection,
  isNavHidden,
  isCenterButtonHidden,
  isMobileReversed,
  isOverflowEnabled,
}) => {
  const { isLoading } = useSelector(stateSelector);

  const layoutContent = interactiveSection ? (
    interactiveSection()
  ) : (
    <BackgroundWrapper background={background} />
  );

  return (
    <LayoutWrapper
      isOverflowEnabled={isOverflowEnabled}
      isMobileReversed={isMobileReversed}
      className={clsx({ isLoading })}
    >
      <LogoLink to="/">
        <LayoutLogo src={logo} alt="Logo Adapciaka" />
      </LogoLink>
      <Menu />
      <ContentWrapper hasAdditionalSections={!!interactiveSection}>
        {smallerHeader && <SmallerHeader>{smallerHeader}</SmallerHeader>}
        {header && (
          <Header isSmaller={!!isHeaderSmaller}>
            {header}
            <span>{green}</span>
          </Header>
        )}
        {children}
        {!isNavHidden && (
          <Navigation
            nextLink={nextLink}
            previousLink={previousLink}
            activePageNumber={currentPageNumber}
          />
        )}
      </ContentWrapper>
      <Wave />
      {layoutContent}
      {!isCenterButtonHidden && (
        <ActionWrapper>
          <Button Icon={ButtonIcon} handleClick={() => buttonAction()} />
          <Ring />
          <Ring />
          <Ring />
        </ActionWrapper>
      )}
    </LayoutWrapper>
  );
};

export default Layout;
