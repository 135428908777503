import styled from 'styled-components';

export const MemberInfoWrapper = styled.div`
  height: 30%;
  margin-bottom: 10vh;
  animation: fade-in-without-scale 1500ms 1;

  @media only screen and (max-width: 768px) {
    height: 60%;
    overflow-y: auto;
    margin-bottom: 0;
  }

  @keyframes fade-in-without-scale {
    from {
      transform: translateX(-6vw);
      opacity: 0;
    }

    to {
      transform: translateX(0vw);
      opacity: 1;
    }
  }
`;

export const MemberNameHeader = styled.h3`
  font-size: clamp(2em, 1.5vw, 5em);
  font-family: BalooChettan2ExtraBold;
  margin: 2em 2.5vw 0.2em;
  text-transform: uppercase;

  @media only screen and (max-width: 768px) {
    margin: 0 2.5vw 0.2em;
    padding: 0.3em 0 0 0;
  }
`;

export const MemberStudyInfoHeader = styled.h3`
  font-size: clamp(1em, 1vw, 2em);
  font-family: ProximaNova;
  margin: 0em 2.5vw 2em;
  text-transform: uppercase;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;
