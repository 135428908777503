import { Filters as FiltersType } from 'api/panel';
import React from 'react';
import {
  Checkmark,
  Container,
  FiltersWrapper,
  FormData,
  SearchIput,
} from './styled';

interface FiltersProps {
  handleFilterClick: (value: FiltersType) => void;
  handleSearchInput: (value: string) => void;
  search: string;
}

const Filters = ({
  handleFilterClick,
  handleSearchInput,
  search,
}: FiltersProps) => {
  return (
    <FiltersWrapper>
      <FormData>
        Tylko zarejestrowani
        <Container>
          <input
            onChange={() => handleFilterClick(FiltersType.REGISTERED_ONLY)}
            type="checkbox"
          />
          <Checkmark />
        </Container>
      </FormData>
      <FormData>
        Opłacone
        <Container>
          <input
            onChange={() => handleFilterClick(FiltersType.PAID)}
            type="checkbox"
          />
          <Checkmark />
        </Container>
      </FormData>
      <FormData>
        Potwierdzone
        <Container>
          <input
            onChange={() => handleFilterClick(FiltersType.CONFIRMED)}
            type="checkbox"
          />
          <Checkmark />
        </Container>
      </FormData>
      <FormData>
        <SearchIput
          placeholder="Wyszukaj"
          value={search}
          onChange={e => handleSearchInput(e.target.value)}
        />
      </FormData>
    </FiltersWrapper>
  );
};

export default Filters;
