import styled from 'styled-components';

interface WithBGColor {
  backgroundColor: string;
}

export const TileWrapper = styled.div<WithBGColor>`
  width: 28%;
  justify-content: center;
  align-items: center;
  height: 120px;
  background-color: ${props => props.backgroundColor};
  margin-top: 3vh;
  border-radius: 10px;
  border-bottom: 5px solid ${props => props.color};
  min-width: 200px;
  box-shadow: #00000044 0 0 7px;
  display: flex;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const TileIcon = styled.div`
  height: 100%;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;

  & div {
    padding: 20px;
    border-radius: 50%;

    background-color: ${props => props.color};
    & svg {
      height: 40px;
      width: 40px;
      display: block;
      fill: white;
      stroke: white;
    }
  }
`;

export const TileContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  font-family: ProximaNovaBold;
  text-align: center;

  & p {
    font-size: clamp(2em, 2vw, 2.35em);
    margin: 0;
    text-transform: uppercase;
  }

  & span {
    font-size: 5.5em;
  }
`;
