import styled from 'styled-components';

export const NavigationWrapper = styled.nav`
  position: absolute;
  left: 50%;
  bottom: 3vh;
  transform: translateX(-50%);
  width: calc(100% - 10vw);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 3.5rem;
  font-family: ProximaNovaBold;

  @media only screen and (max-width: 768px) {
    bottom: 0vh;
  }

  a {
    display: flex;
    align-items: center;
    max-height: 40px;
    font-size: 4.5rem;

    svg {
      path {
        transition: 0.2s all;
      }
    }

    &:hover {
      svg {
        path {
          stroke: ${props => props.theme.colors.main};
        }
      }
    }
  }
`;

export const PagesIndicator = styled.div`
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    width: 60%;
  }

  span {
    margin: 0 5px;
    white-space: pre;
  }

  span:not(:first-of-type) {
    opacity: 0.3;
  }
`;
