import { ContainerState, ContainerActions } from './types';
import { Reducers, Reducer } from 'types/reducers';
import { ActionTypes } from './constants';
import { Participant } from 'api/users';

// The initial state of the App
export const initialState: ContainerState = {
  currentUser: null,
  isLoading: false,
  isSidebarMenuOpen: false,
};

type ReducerActions = ContainerActions;

const reducers: Partial<Reducers<ReducerActions, ContainerState>> = {
  [ActionTypes.TOGGLE_MENU]: () => state => ({
    ...state,
    isSidebarMenuOpen: !state.isSidebarMenuOpen,
  }),
  [ActionTypes.SET_LOADING]: payload => state => ({
    ...state,
    isLoading: payload.isLoading,
  }),
  [ActionTypes.PARTICIPANT_LOGIN_SUCCESS]: payload => state => ({
    ...state,
    currentUser: payload.user,
    isLoading: false,
  }),
  [ActionTypes.PARTICIPANT_PAYMENT_SUCCESS]: () => state => ({
    ...state,
    currentUser: { ...(state.currentUser as Participant), hasPaid: true },
  }),
};

// Take this container's state (as a slice of root state), this container's actions and return new state
function appReducer(
  state: ContainerState = initialState,
  action: ContainerActions,
): ContainerState {
  const { type, payload } = action;

  const reducer:
    | undefined
    | Reducer<typeof type, ContainerActions, ContainerState> = reducers[type];

  return reducer ? reducer(payload)(state) : state;
}

export default appReducer;
