import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route } from 'react-router-dom';

interface ProtectedRouteProps {
  path: string;
  component: () => JSX.Element;
}

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ path, component }) => {
  const { currentUser } = useSelector(stateSelector);

  return currentUser ? <Route exact path={path} component={component} /> : null;
};

export default ProtectedRoute;
