import styled from 'styled-components';

export const CarouselleWrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 40vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100vw;
    height: 60vh;
  }

  & > .swiper {
    width: 100%;
    height: 100%;
  }

  & .swiper-slide {
    display: flex;
    align-items: center;
  }
`;
