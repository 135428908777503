import { getUserDetails } from 'api/users';
import { useEffect, useState } from 'react';
import { Avatar, AvatarWrapper } from './styled';

export const CaptainAvatar = ({
  captainId,
  color,
}: {
  captainId: string;
  color: string;
}) => {
  const [avatar, setAvatar] = useState<string | undefined>();
  useEffect(() => {
    getUserDetails(captainId).then(res => setAvatar(res.avatar));
  }, [captainId]);
  return (
    <AvatarWrapper>
      <Avatar url={avatar} color={color} />
    </AvatarWrapper>
  );
};
