export const colors = {
  white: '#ffffff',
  // main: 'rgb(180, 50, 145)',
  // mainFaded: 'rgb(180, 50, 145, 0.5)',
  // mainDark: 'rgb(180, 50, 145)',
  // mainDark30: 'rgb(180, 50, 145, 0.3)',

  main: 'rgb(4,60,199)',
  mainFaded: 'rgba(4,60,199, 0.5)',
  mainDark: 'rgba(2,31,103)',
  mainDark30: 'rgba(2,31,103, 0.3)',

  // main: 'rgb(234, 89, 34)',
  // mainFaded: 'rgba(234, 89, 34, 0.5)',
  // mainDark: 'rgba(210, 80, 30)',
  // mainDark30: 'rgba(210, 80, 30, 0.3)',
};

const theme = {
  colors,
};

export type Theme = typeof theme;

export default theme;
