import styled from 'styled-components';

const missingAvatar =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/1024px-Missing_avatar.svg.png';

export const Avatar = styled.div<{ url: string | undefined }>`
  background-color: #bfbfbd;
  border-radius: 50%;
  width: 3vw;
  height: 3vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.url ?? missingAvatar});
  margin: 0 2% 0 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const UserWrapper = styled.div<{ color?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 90px;
  height: 90px;
  width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  border-bottom: 2px solid #eff0f2;
  font-family: ProximaNova;
  font-size: clamp(1rem, 1vw, 2rem);
  z-index: 0;

  @media (max-width: 768px) {
    font-size: 3em;
  }

  ${Avatar} {
    border: 3px solid ${props => (props.color ? props.color : 'none')};
  }
`;

export const Index = styled.span`
  width: 8%;
  text-align: center;
  font-family: ProximaNovaBold;

  @media (max-width: 768px) {
    font-size: 0.7em;
    width: 5%;
  }
`;

export const Name = styled.p`
  display: flex;
  width: 17.5%;
  text-align: center;

  @media (max-width: 768px) {
    width: 25%;
    font-size: 0.7em;
    text-align: center;
  }
`;

const Status = styled.p`
  color: white;
  margin: 0 2.5%;
  text-align: center;
  font-size: 0.5em;
  width: 10%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;

  @media (max-width: 768px) {
    width: 15%;
    margin: 0;
  }
`;

export const StatusRegistered = styled(Status)`
  background-color: #bfbfbd;
`;

export const StatusPaid = styled(Status)`
  background-color: #f5ce42;
`;

export const StatusConfirmed = styled(Status)`
  background-color: #32a852;
`;

export const AcademicData = styled.p`
  width: 22%;
  display: flex;
  font-size: 0.7em;

  @media (max-width: 768px) {
    width: 25%;
    font-size: 0.5em;
  }
`;

export const Management = styled.div`
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const DownloadConfirmation = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5ce42;
  font-size: 0.6em;
  text-decoration: none;
  text-align: center;
  color: black;
  border-radius: 8px;
  width: 40%;
  height: 50%;
  text-transform: uppercase;
  margin: 0 0.5em;

  @media (max-width: 768px) {
    font-size: 0.5em;
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #32a852;
  font-size: 0.6em;
  text-decoration: none;
  text-align: center;
  color: black;
  border-radius: 8px;
  width: 40%;
  height: 50%;
  text-transform: uppercase;
  border: none;
  margin: 0 0.5em;
  color: white;

  @media (max-width: 768px) {
    font-size: 0.5em;
  }

  &:hover {
    cursor: pointer;
  }
`;
