import styled from 'styled-components';
import theme from 'theme/theme';

const onHoverColor = theme.colors.main;

export const MenuEntryWrapper = styled.div<{ restColor: string }>`
  border-bottom: 0.2rem solid ${props => props.restColor};
  display: flex;
  align-items: center;
  padding: 0.4vw 0.25vw;

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 768px) {
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &:nth-child(1),
  &:nth-child(2) {
    svg {
      path {
        fill: ${props => props.restColor};
      }
    }
  }

  &:nth-child(3) {
    svg {
      path {
        stroke: ${props => props.restColor};
      }
    }
  }

  &:hover {
    border-bottom: 0.6rem solid ${onHoverColor};

    & > * {
      svg {
        path {
          color: ${onHoverColor};
          fill: ${onHoverColor};
          stroke: ${onHoverColor};
        }
      }
    }
  }

  &:first-of-type {
    padding-top: 0;
  }
`;

export const MenuEntryDescription = styled.a<{ restColor: string }>`
  font-size: clamp(2.2em, 0.9vw, 1.2vw);
  padding: 0 1rem;
  width: 100%;
  font-family: ProximaNova;
  color: ${props => props.restColor};

  ${MenuEntryWrapper}:hover & {
    color: ${onHoverColor};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
