import { updateTeamScore } from 'api/panel';
import { BaseSyntheticEvent, useState } from 'react';
import { Button, Counter, Input } from './styled';

export const ScoreInput = ({
  teamScore,
  color,
}: {
  teamScore: number;
  color: string;
}) => {
  const [amount, setAmount] = useState<number>(0);
  const handleIncrement = () => {
    updateTeamScore({ color, score: teamScore + amount });
  };
  const handleDecrement = () => {
    if (teamScore === 0) return;
    if (teamScore - amount < 0) {
      updateTeamScore({ color, score: 0 });
      return;
    }
    updateTeamScore({ color, score: teamScore - amount });
  };
  return (
    <Counter>
      <Button onClick={() => handleIncrement()}>+</Button>
      <Input
        type="number"
        onChange={(e: BaseSyntheticEvent) => {
          setAmount(parseInt(e.target.value));
        }}
        value={amount}
        min={0}
      />
      <Button onClick={() => handleDecrement()}>-</Button>
    </Counter>
  );
};
