import { createGlobalStyle } from 'styled-components';
import ProximaNovaBold from './fonts/ProximaNovaBold.otf';
import Rubik from './fonts/RubikMonoOne-Regular.ttf';
import ProximaNova from './fonts/ProximaNovaRegular.otf';
import PermanentMarker from './fonts/PermanentMarker-Regular.ttf';
import BalooChettan2ExtraBold from './fonts/BalooChettan2-ExtraBold.ttf';

/* istanbul ignore next */
export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    font-size:62.5%;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  .image-gallery {
    margin: 20vh 0 0 0;
    width: 40vw;

    @media only screen and (max-width: 768px) {
      margin: 13vh 0 0 0;
      width: 90vw;
    }
  }

  .image-gallery-svg, .image-gallery-icon {
    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }

  .image-gallery-thumbnail.active, .image-gallery-thumbnail:hover {
    border: 4px solid ${props => props.theme.colors.main};
  }

  .image-gallery-thumbnail:hover {
    cursor: pointer;
  }

  .image-gallery-content {
    &.fullscreen {
      @media only screen and (max-width: 768px) {
        margin: 25vh 0 0 0;
      }
    }
  }

  @font-face {
    font-family: ProximaNova;
    src: url(${ProximaNova});
  }

  @font-face {
    font-family: ProximaNovaBold;
    src: url(${ProximaNovaBold});
  }

  @font-face {
    font-family: Rubik;
    src: url(${Rubik});
  }

  @font-face {
    font-family: PermanentMarker;
    src: url(${PermanentMarker});
  }

  @font-face {
    font-family: BalooChettan2ExtraBold;
    src: url(${BalooChettan2ExtraBold});
  }

  *{
    margin: 0;
    padding: 0;
  }
`;
