import styled from 'styled-components';

export const CTA = styled.button`
  background-color: ${props => props.theme.colors.main};
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  border: ${props => `solid 1.5px ${props.theme.colors.mainDark30}`};
  filter: ${props => `drop-shadow(0 0 1.5rem ${props.theme.colors.mainFaded})`};
  color: ${props => props.theme.colors.white};
  font-size: clamp(3rem, 3vw, 5rem);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  transition: 0.3s all;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.mainDark};
    filter: ${props =>
      `drop-shadow(0 0 2.5rem ${props.theme.colors.mainFaded})`};
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
