import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import fetch from 'cross-fetch';

const client = new ApolloClient({
  // eslint-disable-next-line no-underscore-dangle
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: 'https://sspl-api.bambit.com.pl/graphql',
    fetch,
  }),
});

export default client;
