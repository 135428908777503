import React, { useState } from 'react';
import {
  MenuWrapper,
  MenuIcon,
  CloseIcon,
  MenuEntries,
  EntriesList,
  Entry,
} from './styled';
import { Link } from 'react-router-dom';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'api/users';
import history from 'utils/history';
import { participantLogout } from 'containers/App/actions';
import { useClickOutsideHandler } from 'hooks/useClickOutsideHandler';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const Menu = () => {
  const [isActive, setIsActive] = useState(false);
  const { currentUser } = useSelector(stateSelector);
  const ref = React.createRef<HTMLDivElement>();

  const dipatch = useDispatch();

  const handleLogout = async () => {
    await logout();
    dipatch(participantLogout());
    history.push('/');
  };

  useClickOutsideHandler(ref as React.MutableRefObject<HTMLDivElement>, () =>
    setIsActive(false),
  );

  return (
    <>
      <MenuEntries ref={ref} isActive={isActive}>
        <EntriesList>
          <Entry>
            <Link to="/">Start</Link>
          </Entry>
          <Entry>
            <Link to="/about">O Adapciaku</Link>
          </Entry>
          <Entry>
            <Link to="/gallery">Galeria</Link>
          </Entry>
          {/* <Entry>
            <Link to="/partners">Partnerzy</Link>
          </Entry> */}
          <Entry>
            <Link to="/scoreboard">Punktacja</Link>
          </Entry>
          <Entry>
            <Link to="/team">Kadra</Link>
          </Entry>
          <Entry>
            <Link to="/faq">FAQ</Link>
          </Entry>
          {currentUser ? (
            <>
              <Entry>
                <Link to="/account">Konto</Link>
              </Entry>
              <Entry>
                <Link to="/payment">Płatność</Link>
              </Entry>
              <Entry>
                <Link onClick={() => handleLogout()} to="/">
                  Wyloguj
                </Link>
              </Entry>
            </>
          ) : (
            <>
              <Entry>
                <Link to="/login">Logowanie</Link>
              </Entry>
              <Entry>
                <Link to="/register">Rejestracja</Link>
              </Entry>
            </>
          )}
          {currentUser?.isAdmin && (
            <Entry>
              <Link to="/admin-panel">Panel Admina</Link>
            </Entry>
          )}
        </EntriesList>
      </MenuEntries>
      <MenuWrapper isActive={isActive} />
      {isActive ? (
        <CloseIcon onClick={() => setIsActive(false)} />
      ) : (
        <MenuIcon onClick={() => setIsActive(true)} />
      )}
    </>
  );
};

export default Menu;
