import { TeamMemberType } from './types';
// import questionmark from '../../assets/team/questionMark.png';
import ala from '../../assets/team/kadra_ala.jpeg';
import amelia from '../../assets/team/kadra_amelia.jpeg';
import antek from '../../assets/team/kadra_antek.jpeg';
import asia from '../../assets/team/kadra_asia.jpeg';
import dawid from '../../assets/team/kadra_dawid.jpeg';
import filip from '../../assets/team/kadra_filip.jpeg';
import janek from '../../assets/team/kadra_janek.jpeg';
import jula from '../../assets/team/kadra_jula.jpeg';
import krzysiu from '../../assets/team/kadra_krzysiu.jpeg';
import milosz from '../../assets/team/kadra_milosz.jpeg';
import ola from '../../assets/team/kadra_ola.jpeg';
import sylwiaS from '../../assets/team/kadra_sylwia_s.jpeg';
import sylwiaP from '../../assets/team/kadra_sylwia.jpeg';
import zuzia from '../../assets/team/kadra_zuzia.jpeg';

export const members: ReadonlyArray<TeamMemberType> = [
  {
    name: 'Aleksandra Mikołajczyk',
    role: 'Koordynator',
    studentInfo: '',
    description:
      'Ola zmienia hobby średnio raz w miesiącu, towarzyska ekstrawertyczka, no chyba że jest rano albo jest głodna - lepiej uważać 😌. Jej ulubiona sentencja to „per aspera ad astra”, dlatego studiuje informatykę stosowaną na IFE na czwartym roku. To będzie też jej czwarty raz na Adapciaku 😍. Miłośniczka języków obcych, zdarza jej się mowić pół-english half-polsku, więc jest się z czego śmiać. Często opowiada (nie)śmieszne żarty, ale jak sie uśmiechniecie, to nie zauważy 😎. My tak robimy i działa!',
    img: ola,
    alt: 'Aleksandra Mikołajczyk',
  },
  {
    name: 'Julia Gwóźdź',
    role: 'Zastępca',
    studentInfo: '',
    description:
      'Jula znana jest z poruszania się wszędzie swoją czerwoną wyścigówką, którą bardzo chciałaby przemalować na różowo 🏎️. Pasjonatka zakupów, fotografii i pozyskiwania partnerów 💰. Nie da się nabrać na niczyje bajki, nawet wtedy, gdy jest głęboko pogrążona w serialu. Bardzo słodka, chyba, że uczestnik nie wpadnie na to, żeby zabrać ze sobą leki na ból gardła. Wtedy i my trochę się boimy 😅.',
    img: jula,
    alt: 'Julia Gwóźdź',
  },
  {
    name: 'Filip Wiśniewski',
    role: 'Formalności, Współpraca zewnętrzna, Techniczni',
    studentInfo: '',
    description:
      'Filip zajmuje się wszystkim na raz, więc często nie wie w co włożyć ręce, w odpowiednim stanie jest duszą towarzystwa i wszędzie go pełno🥳 Na codzień rzuca paczkami w okresie wakacyjnym, a w czasie wolnym lubi spedzic czas ze znajomymi lub poklikać w komputer🤓 Z zainteresowania komputerami skończył na systemach sterowania inteligentnymi budynkami na wydziale WEEiA na drugim roku😅 Nigdy nie odmawia partii we flanki🍺',
    img: filip,
    alt: 'Filip Wiśniewski',
  },
  {
    name: 'Zuzanna Jachowicz',
    role: 'Współpraca zewnętrzna, Finanse',
    studentInfo: '',
    description:
      'Jako studentka BHP Zuza nie znosi hasła „bezpieczeństwo hamuje pracę”, więc prędzej zobaczycie ją mknącą ✨srebrną Hondą Jazz✨ z jednej pracy do drugiej. Wiecznie zajęta, ale zawsze znajdzie chwilę na ploteczki ze znajomymi i poszukiwanie nowych miejsc z pysznym jedzonkiem 🥟. W chwilach oddechu najpewniej sięgnie po dobrą książkę📖.Uwielbia długie spacery i jogę, ale na wieczornych integracjach najpewniej wcieli się w „Dancing Queen” jak tylko usłyszy piosenki ABBY 💃🎶.',
    img: zuzia,
    alt: 'Zuzanna Jachowicz',
  },
  {
    name: 'Miłosz Wierucki',
    role: 'IT',
    studentInfo: '',
    description:
      'Kiedy inni zastanawiają się, jak złamać kod, Miłosz już dawno odkrył jego tajemnicze formuły! 🧙🏼‍♂️ Studiując informatykę stosowaną, wkracza w świat niekończących się algorytmów i cyfrowych zagadek.  Nie myślcie jednak, że cały jego świat to tylko klawiatura i ekran – w przerwach między kodowaniem Miłosz nie zapomina o dbaniu o formę na siłowni 🏋️, a kiedy poziom energii spada, ratuje go jego ulubiony arsenał monsterków! ⚡️🔥',
    img: milosz,
    alt: 'Miłosz Wierucki',
  },
  {
    name: 'Amelia Sałek',
    role: 'Formalności, Rozrywka',
    studentInfo: '',
    description:
      'Amelia zajmuje się dbaniem o prawa studentów i pomaga w organizacji zajęć na Adapciaku💥. Jest studentką kierunku Computer Science, ale bardziej udziela się w dodatkowych zajęciach na uczelni, trochę zrzucając studia na drugi plan 🫣 Taco Hemingway gra w jej słuchawkach niemal codziennie 💣, a kapibary to jej ulubione stworzenia, które zawsze potrafią poprawić jej nastrój 🤩',
    img: amelia,
    alt: 'Amelia Sałek',
  },
  {
    name: 'Joanna Redzynia',
    role: 'Kontakt z uczestnikami, Promocja',
    studentInfo: '',
    description:
      'Poznajcie Asię, dziewczynę, która zawsze stawia dobro innych na pierwszym miejscu, choć jej spojrzenie czasem może wyglądać, jakby chciała Was zabić – nie bójcie się, to tylko jej sposób na utrzymanie wszystkiego pod kontrolą! 😅 Asia, a właściwie Asiula, bo tak uwielbia, gdy się do niej mówi, to urodzona organizatorka, która uwielbia mieć wszystko dopięte na ostatni guzik, ale kiedy przychodzi czas na imprezy, potrafi bawić się do białego rana. Spontaniczne pomysły to jej żywioł, a słowo „nuda” po prostu dla niej nie istnieje. Latem Asia króluje w Chałupach, pływając na windsurfingu i kitesurfingu, a także zajmując się dzieciakami – więc nie zdziwcie się, jeśli będzie dla Was jak druga mama. Jej nerwy są ze stali, a wyczucie stylu i elegancji ma w małym palcu, choć na Adapciaku możecie tego nie zauważyć 😜. Mówią na nią Zygzak, bo wolnej i nudnej jazdy nie akceptuje – jest najszybszym kierowcą w Łodzi! Jeśli szukacie kogoś, kto doda Waszemu życiu szczyptę szaleństwa i energii, Asiula jest osobą, z którą zdecydowanie warto trzymać blisko! 🏄‍♀️🚗',
    img: asia,
    alt: 'Joanna Redzynia',
  },
  {
    name: 'Alicja Bielewska',
    role: 'Promocja, Grafiki',
    studentInfo: '',
    description:
      'Poznajcie Alę, studentkę Business Studies, duszę towarzystwa i szefową promki Adapciaka 😙 Na Polibudzie krąży już legenda o jej niezawodnym wyczuciu stylu, choć sama przyznaje, że na obozie pewnie będzie wyglądać jak bezdomna 😜 W końcu, to ona jest przewodniczącą komisji ds. marketingu w samorządzie, więc wie, jak przyciągnąć uwagę i zrobić wrażenie 🤭 Jej głośny śmiech i nieskończona liczba anegdot towarzyszą każdej imprezie 🪩 Nie sposób jej nie usłyszeć, a jak już usłyszysz, to zapomnieć się nie da 😬 Kocha szukać inspiracji i tworzyć cool plakaty, a jak tylko ją stać na klisze, to nie rusza się z domu bez swojego analoga 📸 Na obozie nauczy Was, jak robić idealny wir i wywijać na parkiecie, więc podbijajcie śmiało! 😎',
    img: ala,
    alt: 'Alicja Bielewska',
  },
  {
    name: 'Dawid Borkowski',
    role: 'Techniczne, Rozrywka',
    studentInfo: '',
    description:
      'Dawid to perfekcjonista, który lubi wszystko mieć dopięte na ostatni guzik ✅ To właśnie on odpowiada za to, żeby na tym wyjeździe niczego nam nie zabrakło i żebyśmy mogli bawić się do białego rana 🥳🪩 Marzyciel, który chętnie wymyśla sobie zbyt ambitne projekty – w końcu bez wyzwań niczego się nie nauczymy 😜 Uwielbia psy, programowanie i czas spędzony w kuchni na odkrywaniu zagranicznych smaków 🐶💻 Całe życie związany ze sportem, najbardziej lubi piłkę nożną ⚽️, tenis 🎾 i surfowanie 🏄‍♂️, które niedawno odkrył na falach słonecznej Portugalii 🇵🇹 Po obejrzeniu po raz 22 swojego ulubionego „Top Gun’a” myślał o karierze wojskowego pilota ✈️, ale na samym starcie pokonała go wada wzroku i wybrał pilot od telewizora 😅 W wolnych chwilach kocha porządnie się wyspać 😴 Na Adapciaku z chęcią opowie Wam, jak przetrwać na studiach, i jednocześnie zbierać wspomnienia na całe życie 😎🔥',
    img: dawid,
    alt: 'Dawid Borkowski',
  },
  {
    name: 'Jan Krakowiak',
    role: 'Rozrywka, Techniczne',
    studentInfo: '',
    description:
      'Siema tu Jano, fanatyk melanżu i wirtuoz imprez🕺 Poza tym student analityki chemicznej⚗️, człowiek żyjący sportem i motoryzacją. Dusza towarzysta, zawsze chętny na pogawędki do samego rana🌅 Myśli to co czuje, mówi to co myśli. Na adapciaku posiada alter ego - menadżer Klubu Odloty🚀 To między innymi dzięki niemu ten ADAPCIAK będzie dla was niezapomniany. Jeżeli zgubicie coś na adapciaku to wbrew pozorom nie znajdziecie tego u mnie.',
    img: janek,
    alt: 'Jan Krakowiak',
  },
  {
    name: 'Krzysztof Magin',
    role: 'Rozrywka, Techniczne',
    studentInfo: '',
    description:
      'Krzysztof nie umie pisać swoich opisów. Jest studentem Business and Technology chociaż niektórzy mają wątpliwości czy cały czas tu studiuje. Jest obecny na każdej możliwej imprezie, ale nie pamięta części z nich. W wolnym czasie gra w spajka i chodzi na siłownię. Oprócz tego uwielbia latać czy to samolotem czy na imprezie. Niektórzy mowią na niego spiderman przekonacie się dlaczego. Krzysiu zaprasza was na wspólne śpiewanie Malika nad ranem.',
    img: krzysiu,
    alt: 'Krzysztof Magin',
  },
  {
    name: 'Antek Pluta',
    role: 'Rozrywka, Techniczne',
    studentInfo: '',
    description:
      'Antoni Pluta  - student 2 roku business society and technology, w samorządzie jest pełnomocnikiem do spraw IFE oraz członkiem komisji ds. rozwoju. Jego lok zakręcony jest jak fale, na których pływa. Z jego pasji na pewno można wymienić windsurfing, gotowanie, tańce oraz perfumy. Jeżeli Imprezowanie do samego rana byłoby dyscypliną olimpijską, to Antoni byłby godny zawodnik do ubiegania się o medal. Jego styl tańca można opisać jako imprezowo-towarzyski, więc na imprezach będzie tańczyć z Wami, dopóki nie zedrze podeszw o parkiet.',
    img: antek,
    alt: 'Antek Pluta',
  },
  {
    name: 'Sylwia Piaseczna',
    role: 'Rozrywka, Kontakt z uczestnikami',
    studentInfo: '',
    description:
      'Sylwia studiuje zarządzanie i inżynierię produkcji, ale ostatnio najbardziej skupia się na organizowaniu swojego czasu, żeby przypadkiem nie wkradła się nuda 🗓️ Jej zainteresowania ciężko jednoznacznie określić, bo ciągle znajduje jakieś nowe zajawki 🎡  Człowiek orkiestra 🎶 Uwielbia muzykę, koncerty i festiwale, więc nie pogardzi też imprezą do białego rana 💃 oraz karaoke w Indeksie 🎙️ Żeby poczuć dreszczyk emocji, ogląda mecze siatkówki i nie przepuści też okazji, żeby poodbijać piłkę 🏐 Amatorsko pstryka zdjęcia, głównie przyrody i swojego psa 🐶, a także zdarza się, że przejmuje rolę fotografa na imprezach 📸  Wyznaje zasadę, że kiedyś jeszcze zdąży się wyspać, na razie nie ma na to czasu 😅',
    img: sylwiaP,
    alt: 'Sylwia Piaseczna',
  },
  {
    name: 'Sylwia Sielska',
    role: 'Promocja, Grafiki',
    studentInfo: '',
    description:
      'Informatyka to jej dziedzina, ale organizacja to jej supermoc 💻📊 Sylwia to mistrzyni porządku, która wie, jak cieszyć się chwilą – kiedy pojawia się szansa na spontaniczny wypad, zawsze jest gotowa, by zamienić zwykły dzień w niezapomnianą przygodę! 🏕️🌄 Zna się na dobrej zabawie jak mało kto, co udowadnia organizując Adapciaka już po raz trzeci! 🌟 Lizbona, gdzie spędziła swojego Erasmusa, stała się jej drugim domem 🇵🇹🐟 Surfing, słońce i plaże – to tam utwierdziła się w tym, że życie to jedna wielka przygoda, którą trzeba przeżyć na maksa 🏖️🌞🏄‍♂️ W kuchni jest prawdziwą eksperymentatorką - uwielbia gotować i tworzyć nieoczywiste połączenia smaków, a rosół to jej kulinarny klasyk na każdą okazję 🍲 Zakochana w parkach linowych, rollercoasterach i długich przejażdżkach samochodem🎢🚗 Choć Sylwia żyje na pełnych obrotach, wie, jak się zrelaksować. Idealny chill to dla niej krzyżówki w ręku i koty przy boku, jak na prawdziwą kociarę przystało🐈🧩',
    img: sylwiaS,
    alt: 'Sylwia Sielska',
  },
];
