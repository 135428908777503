import styled from 'styled-components';
import { TilesWrapper } from '../Tiles/styled';

export const ScoreBoardTilesWrapper = styled(TilesWrapper)<{
  isAdmin: boolean;
}>`
  height: ${props => (props.isAdmin ? '' : '40vh')};
  padding: 1rem 0;
  z-index: 1;

  @media (min-width: 768px) {
    padding: 5rem 0;
    width: ${props => (props.isAdmin ? '' : '40vw')};
    height: ${props => (props.isAdmin ? '' : '100%')};
  }
`;
