import Layout from 'components/Layout';
import { Content, SmallerHeader } from 'components/Layout/styled';
import PartnersList from 'components/PartnersList';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import history from 'utils/history';

import bg from 'assets/landing/landingBg.jpg';

export const Partners = () => {
  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="PARTNERZY"
      green={''}
      nextLink="/"
      previousLink="/faq"
      currentPageNumber={6} // 4
      isCenterButtonHidden
      buttonAction={() => history.push('/register')}
      isMobileReversed
      interactiveSection={PartnersList}
    >
      <SmallerHeader>Partnerzy Adapciaka</SmallerHeader>
      <Content>
        W tym roku nasz wyjazd wspiera wielu partnerów, których pomoc podnosi
        jakość wyjazdu zarówno uczestników, jak i organizatorów.
        {/* Już niedługo poznacie partnerów, którzy wesprą tegoroczny wyjazd! */}
      </Content>
    </Layout>
  );
};
