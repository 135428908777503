import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Form = styled.form<{ height?: string }>`
  white-space: pre-wrap;
  font-size: 2rem;
  height: ${props => (props.height ? `${props.height}vh` : 'auto')};
  font-family: ProximaNova;
  padding: 0 2.5vw;
  margin: 0 0 2.5rem 0;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30vw;

  @media only screen and (max-width: 768px) {
    width: 80vw;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: 1.5em;
  padding: 0.4em 0.2em;
  margin: 0.5em 0;
  border: 2px solid ${props => props.theme.colors.main};
  border-radius: 8px;
  position: relative;
  transition: all 0.1s;
  box-sizing: content-box;

  &::placeholder {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 0.6em;
  }
`;

export const ResetLink = styled(Link)`
  font-family: ProximaNova;
  text-decoration: none;
  color: black;
  margin: 0 0 0 auto;

  &:hover {
    color: ${props => props.theme.colors.main};
  }
`;

export const Button = styled.button`
  margin: 1.5em 0 0 0;
  width: 10vw;
  padding: 0.5em 0.2em;
  background-color: ${props => props.theme.colors.main};
  border-radius: 8px;
  border: none;
  transition: all 0.3s;

  @media only screen and (max-width: 768px) {
    width: 50vw;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.mainDark};
  }
`;

export const ErorrMsg = styled.span`
  color: red;
`;
