import styled from 'styled-components';

export const TilesWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0 2.5vw;
  transition: all 0.3s;
  animation: fade-in-panel 1500ms 1;

  @media (max-width: 768px) {
    margin: 0 2vw;
    overflow-y: auto;
  }

  @keyframes fade-in-panel {
    from {
      transform: translateY(-2.2vh);
      opacity: 0;
    }

    to {
      transform: translateY(0vw);
      opacity: 1;
    }
  }
`;
