import styled from 'styled-components';
import { GiPartyPopper } from 'react-icons/gi';
import { IoIosTimer } from 'react-icons/io';
import { TiCancel } from 'react-icons/ti';

export const Warning = styled.span`
  color: red;
  font-weight: 900;
  opacity: 1;
`;

export const Button = styled.button`
  margin: 1.5em 0 0 0;
  width: 10vw;
  padding: 0.5em 0.2em;
  background-color: ${props => props.theme.colors.main};
  border-radius: 8px;
  border: none;
  transition: all 0.3s;

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.mainDark};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: grey;
  }

  @media only screen and (max-width: 768px) {
    width: 50vw;
    height: 7vh;
  }
`;

export const FeedbackMsg = styled.h1`
  text-align: center;
  font-size: 1.5em !important;
  font-family: ProximaNovaBold;
  font-weight: 900;
`;

export const PartyIcon = styled(GiPartyPopper)`
  margin: 0.5em 0 0 0;
  font-size: 9rem;
  color: ${props => props.theme.colors.main};

  @media only screen and (max-width: 768px) {
    margin: 0.2em 0 0 0;
  }
`;

export const WaitIcon = styled(IoIosTimer)`
  margin: 0.5em 0 0 0;
  font-size: 9rem;
  color: ${props => props.theme.colors.main};

  @media only screen and (max-width: 768px) {
    margin: 0.2em 0 0 0;
  }
`;

export const ClosedIcon = styled(TiCancel)`
  margin: 0.5em 0 0 0;
  font-size: 9rem;
  color: ${props => props.theme.colors.main};

  @media only screen and (max-width: 768px) {
    margin: 0.2em 0 0 0;
  }
`;

export const Description = styled.label`
  font-size: clamp(0.3rem, 0.7vw, 1.1rem);
  color: black;
  opacity: 0.5;
  margin: 0.1vh auto 0 0;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;
