import styled from 'styled-components';

interface SummaryWrapperProps {
  isLayerShown: boolean;
}

export const SummaryWrapper = styled.div<SummaryWrapperProps>`
  width: 13vw;
  background-color: #22292f;
  color: white;
  font-size: 1.75rem;
  font-family: ProximaNovaBold;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px 20px 0 0;
  position: fixed;
  bottom: 0px;
  right: 3vw;
  padding: 1rem 0.5rem;
  overflowy: hidden;
  transition: all 0.5s ease-in-out;
  transform: ${props =>
    props.isLayerShown ? 'translateY(0%)' : 'translateY(86%)'};

  li {
    list-style: none;
    margin: 0.5em 0;
    font-size: 1.5rem;
    display: flex;
    align-items: center;

    label {
      margin-left: 0.5em;
      cursor: pointer;

      &:hover {
        color: ${props => props.theme.colors.main};
      }
    }
  }

  .summaryLabel {
    display: grid;
    place-items: center;

    span {
      grid-column: 1 / 3;
    }

    button {
      border-radius: 25%;
      border: 0;
      background: white;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 2rem;
      width: 2rem;

      &:hover {
        background: ${props => props.theme.colors.main};
      }
    }
  }

  @media (max-width: 768px) {
    width: 40vw;
    font-size: 1.5rem;
  }

  a {
    text-decoration: none;
    color: white;
    margin: 0 0 0 0.5em;

    &:hover {
      color: ${props => props.theme.colors.main};
    }
  }
`;
