import styled from 'styled-components';

export const PartnersTBA = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10vh 4vw;
  width: 40vw;

  @media (max-width: 768px) {
    padding: 10vh 10vw 0 10vw;
    width: 90vw;
    height: 50vh;
  }
`;

export const PartnersTBALogo = styled.img`
  width: 100%;
  height: auto;
`;

export const PartnersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow-y: scroll;
  overflow-x: hidden;
  width: 40vw;

  @media (max-width: 768px) {
    padding: 10vh 0 0 0;
    width: 90vw;
    height: 50vh;
  }
`;

export const PartnersLink = styled.a`
  margin: 2em 0;

  &:hover {
    cursor: pointer;
  }
`;

export const PartnerLogo = styled.img`
  width: 20vw;
  height: 20vh;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 50vw;
    height: 10vh;
    margin: 0 auto;
  }
`;
