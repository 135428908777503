import { useEffect, useRef } from 'react';

function useEventListener(
  eventName: string,
  handler: (e: KeyboardEvent) => void,
  element = window,
) {
  const savedHandler = useRef<(e: KeyboardEvent) => void>();

  useEffect(() => {
    if (savedHandler) {
      savedHandler.current = handler;
    }
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener = event => savedHandler.current!(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}

export default useEventListener;
