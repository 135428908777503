import { Form } from 'containers/Register/RegisterForm/styled';
import styled from 'styled-components';

export const EmailsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 2.5vw;
  transition: all 0.3s;
  animation: fade-in-panel 1500ms 1;

  @media (max-width: 768px) {
    margin: 0 2vw;
    overflow-y: auto;
    height: 93vh;
  }

  @keyframes fade-in-panel {
    from {
      transform: translateY(-2.2vh);
      opacity: 0;
    }

    to {
      transform: translateY(0vw);
      opacity: 1;
    }
  }

  ${Form} {
    width: 50vw;
    padding-top: 1rem;
  }
`;

export const Header = styled.h1`
  margin: 3em 0 0 0;
  font-size: 3rem;
  font-family: ProximaNova;
  font-weight: 900;
  text-transform: uppercase;
`;
