import { Statistics, getStatistics } from 'api/panel';
import Layout from 'components/Layout';
import { Bold, Content, ContentHeader, Timer } from 'components/Layout/styled';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useEffect, useState } from 'react';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';

import bg from 'assets/landing/landingBg.jpg';
import PaymentForm from './PaymentForm';

import { takeRemainingSlot, restoreRemainingSlot } from 'api/payments';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

export const Payment = () => {
  const { currentUser } = useSelector(stateSelector);

  const [statistics, setStatistics] = useState<Statistics>();

  const [minutes, setMinutes] = useState<number>(0);
  const [seconds, setSeconds] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const statistics = await getStatistics();
      setStatistics(statistics);

      if (
        statistics?.paymentStartDate.toDate() < new Date() && // change paymentStartDate to TestDate if you want to test
        statistics?.remainingSlots > 0 &&
        !currentUser?.hasPaid
      ) {
        takeRemainingSlot();

        const interval = setInterval(() => {
          const now = new Date().getTime();
          const distance =
            Number(localStorage.getItem('expirationDate')) - now || 0;

          setMinutes(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
          setSeconds(Math.floor((distance % (1000 * 60)) / 1000));

          if (localStorage.getItem('payment') === 'true') {
            clearInterval(interval);
            localStorage.removeItem('expirationDate');
            localStorage.removeItem('payment');
          }

          if (distance <= 0) {
            clearInterval(interval);
            restoreRemainingSlot();
          }
        }, 1000);

        return () => clearInterval(interval);
      }

      return;
    })();
  }, [currentUser?.hasPaid]);

  const renderContentBasedOnPool = () => {
    if (statistics && statistics?.remainingSlots > 0) {
      return informationalMessage;
    } else if (statistics && statistics?.remainingSlots <= 0) {
      return (
        <>
          <br />
          <Bold>Miejsca zostały wyprzedane...</Bold>
        </>
      );
    }
    return null;
  };

  const informationalMessage = (
    <>
      Dzielą Cię sekundy od uzyskania miejsca na tegorocznym Adapciaku. Wypełnij
      formularz danymi, które pozwolą nam wpisać Cię na ostateczną listę
      uczestników. W formularzu załącz potwierdzenie płatności wykonanej na
      podany poniżej numer konta. <br /> <br />
      <Bold>Nazwa odbiorcy: </Bold> Rektorat Politechniki Łódzkiej <br />
      <Bold>Numer konta: </Bold> 92 1240 3028 1111 0010 5742 1480 <br />
      <Bold>Kwota: </Bold> 999 zł <br />
      <Bold>Nazwa przelewu: </Bold> {currentUser?.name} {currentUser?.surname},
      ADAPCIAK2024
      <br />
      {/* <Bold>Pozostało miejsc: </Bold> {statistics?.remainingSlots} <br /> */}
    </>
  );

  const notOpenedMessage = (
    <>
      Formularz jest jeszcze <Bold>zamknięty</Bold>. Poinformujemy Cię o
      otwarciu drogą emailową.
    </>
  );

  const isVisible =
    statistics && statistics?.paymentStartDate.toDate() < new Date(); // change paymentStartDate to TestDate if you want to test

  const isVisibleTimer =
    statistics &&
    statistics?.paymentStartDate.toDate() < new Date() &&
    statistics?.remainingSlots > 0 &&
    !currentUser?.hasPaid;

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="Płatności"
      green={''}
      nextLink="/"
      previousLink="/faq"
      currentPageNumber={0}
      isCenterButtonHidden
      interactiveSection={() => <PaymentForm statistics={statistics} />}
      buttonAction={() => history.push('/register')}
      isOverflowEnabled
    >
      <ContentHeader>Sfinalizuj rejestrację</ContentHeader>
      <Content>
        <Bold>Witaj {currentUser?.name}! </Bold>
        {isVisible ? renderContentBasedOnPool() : notOpenedMessage}
      </Content>
      {isVisibleTimer && (
        <Timer>
          {minutes < 10 ? `0${minutes}` : minutes} :{' '}
          {seconds < 10 ? `0${seconds}` : seconds}
        </Timer>
      )}
    </Layout>
  );
};
