import { IconType } from 'react-icons/lib';
import { CTA } from './styled';
interface ButtonProps {
  Icon: IconType;
  handleClick: () => void;
}

const Button: React.FC<ButtonProps> = ({ Icon, handleClick }) => (
  <CTA type="button" onClick={handleClick}>
    <Icon />
  </CTA>
);

export default Button;
