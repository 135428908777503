import Scoreboard from './Scoreboard';
import { ActiveContent } from 'containers/Admin/Panel';
import Emails from './Emails';
import { ContentWrapper } from './styled';
import Tiles from './Tiles';
import Users from './Users';

interface ContentProps {
  activeContent: string | undefined;
}

const Content = ({ activeContent }: ContentProps) => {
  const renderContent = () => {
    switch (activeContent) {
      case ActiveContent.Summary:
        return <Tiles />;
      case ActiveContent.Users:
        return <Users />;
      case ActiveContent.Emails:
        return <Emails />;
      case ActiveContent.Scoreboard:
        return <Scoreboard isAdmin />;
      default:
        return <div>ERROR</div>;
    }
  };

  return <ContentWrapper>{renderContent()}</ContentWrapper>;
};

export default Content;
