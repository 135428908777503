import Layout from 'components/Layout';
import { Content } from 'components/Layout/styled';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import { GiPayMoney as MoneyIcon } from 'react-icons/gi';
import { FaFacebookSquare as FacebookIcon } from 'react-icons/fa';
import { FaInstagram as InstagramIcon } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import history from 'utils/history';

import bg from 'assets/about/about.jpg';
import { ContentWrapper, Header, IconHolder, Question } from './styled';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

export const FAQ = () => {
  const { currentUser } = useSelector(stateSelector);

  return (
    <Layout
      background={bg}
      ButtonIcon={currentUser ? MoneyIcon : TicketIcon}
      header="FAQ"
      nextLink="/partners"
      previousLink="/team" // "/scoreboard"
      currentPageNumber={5} // 6
      buttonAction={() => history.push(currentUser ? '/payment' : '/register')}
      isCenterButtonHidden
      isHeaderSmaller
    >
      <Header>Co warto wiedzieć przed wyjazdem?</Header>
      <ContentWrapper>
        <Content>
          <Question>Gdzie jest wyjazd?</Question>
          <br />
          Łazy koło Mielna, ośrodek EGM Łazy.
        </Content>
        <Content>
          <Question>Kiedy jest wyjazd?</Question>
          <br />
          Wyjazd odbywa się w dniach 31 sierpnia - 6 września.
          <br />
          Wyjeżdżamy w nocy z 30 na 31 sierpnia.
        </Content>
        {/* <Content>
          <Question>Skąd jest wyjazd?</Question>
          <br />
          Ruszamy spod Społecznego Domu Studenta (al. Politechniki 3A). Zbiórka
          o godzinie 1:00 18.09
        </Content> */}
        <Content>
          <Question>Ile to kosztuje?</Question>
          <br />
          999zł
        </Content>
        <Content>
          <Question>Jak tam się dostaniemy?</Question>
          <br />
          Jedziemy wspólnie autokarami.
        </Content>
        <Content>
          <Question>
            Muszę mieć skończone 18 lat żeby pojechać na obóz?
          </Question>
          <br />
          Tak.
        </Content>
        <Content>
          <Question>
            Muszę być studentem Politechniki Łódzkiej aby pojechać?
          </Question>
          <br />
          Wyjazd jest przeznaczony dla studentów Politechniki Łódzkiej, a w
          szczególności osób, które dopiero dostały się na studia.
        </Content>
        <Content>
          <Question>Czy mogę otrzymać fakturę za wyjazd?</Question>
          <br />
          Tak. Faktura VAT może zostać wyłącznie wystawiona na uczestnika, albo
          na właściciela konta bankowego, z którego została zrealizowana
          płatność. Dane nabywcy muszą być zgodne z danymi płatnika.
        </Content>
        <Content>
          <Question>Kiedy ruszają wpłaty?</Question>
          <br />
          Wpłaty ruszają w 3 tygodniu sierpnia. Informacje o rozpoczęciu wpłat
          przekażemy mailowo osobom które zarejestrowały sie na naszej stronie
          oraz ogłosimy to na naszych social mediach.
        </Content>
        <Content>
          <Question>Jak mogę się zarejestrować?</Question>
          <br />
          Klikając w ikonkę biletu na środku strony bądź przechodząc na stronę{' '}
          <a href="https://adapciak.samorzad.p.lodz.pl/register">
            https://adapciak.samorzad.p.lodz.pl/register
          </a>
        </Content>
        <Content>
          <Question>
            Czy mogę odzyskać całą kwotę jeżeli chcę zrezygnować?
          </Question>
          <br />
          Tak jeżeli oficjalnie zrezygnujesz z wyjazdu na 14 dni przed
          rozpoczęciem obozu.
        </Content>
        <Content>
          <Question>
            Czy mogę dojechać później na obóz lub wcześniej z niego wyjechać?
          </Question>
          <br />
          Tak, koniecznie poinformuj o tym koordynatora obozu.
        </Content>
        <Content>
          <Question>Co jest wliczone w cenę?</Question>
          <br />
          W cenę wliczone są:
          <br />
          -przejazd z Łodzi do Łaz i z powrotem
          <br />
          -nocleg
          <br />
          -wyżywienie
          <br />
          -szkolenia
          <br />
          -gadżety obozowe
          <br />
          Oraz oczywiście dobra zabawa =D
        </Content>
        <Content>
          <Question>
            Jeśli masz jakieś pytania, wątpliwości prosimy o kontakt.
            <br />
            Po wiecej informacji zapraszamy na nasze social media
          </Question>
          <br />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <IconHolder href="https://www.facebook.com/adapciaklodz">
              <FacebookIcon />
            </IconHolder>
            <IconHolder href="https://www.instagram.com/obozy_lodz/">
              <InstagramIcon />
            </IconHolder>
          </div>
        </Content>
      </ContentWrapper>
    </Layout>
  );
};
