import styled from 'styled-components';

interface WithHeight {
  height?: string;
}

export const Form = styled.form<WithHeight>`
  white-space: pre-wrap;
  font-size: clamp(1rem, 2vw, 1.8rem);
  height: ${props => (props.height ? `${props.height}vh` : 'auto')};
  font-family: ProximaNova;
  padding: 11vh 2.5vw 0;
  text-align: justify;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 30vw;
  overflow-y: auto;
  overflow-x: hidden;

  @media only screen and (max-width: 768px) {
    width: 80vw;
    max-height: unset;
    padding: 4vh 2.5vw 0;
  }

  h1 {
    font-size: clamp(1rem, 2vw, 2rem);
    margin: 0.2em 0;

    @media only screen and (max-width: 768px) {
      font-size: 2rem !important;
    }
  }
`;

export const Label = styled.label`
  font-size: clamp(0.3rem, 0.7vw, 1.1rem);
  color: black;
  opacity: 0.5;
  margin: 0.9vh auto 0 0;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

export const Agreement = styled.div`
  margin: 1vh 0 0 0;
  display: flex;
  align-items: center;
  font-size: clamp(0.5rem, 1vw, 1rem);
  width: 100%;

  & > span {
    width: 100%;
    max-width: 436px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Invoice = styled.div`
  margin: 1vh 0 0 0;
  display: flex;
  align-items: center;
  font-size: clamp(1.2rem, 1.25vw, 1.75rem);
  line-height: 1.25em;
  width: 100%;

  & > span {
    width: 100%;
    max-width: 436px;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const Selection = styled.select`
  width: 100%;
  font-size: 1.3em;
  padding: 0.4em 0.2em;
  margin: 0.2em 0;
  border: 2px solid ${props => props.theme.colors.main};
  border-radius: 8px;
  position: relative;
  transition: all 0.1s;
  box-sizing: content-box;

  &::placeholder {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 0.6em;
  }
`;

export const Input = styled.input`
  width: 100%;
  font-size: clamp(1em, 1.2vw, 1.3em);
  padding: 0.4em 0.2em;
  margin: 0.2em 0;
  border: 2px solid ${props => props.theme.colors.main};
  border-radius: 8px;
  position: relative;
  transition: all 0.1s;
  box-sizing: content-box;

  @media (max-width: 768px) {
    height: 5vh;
    font-size: 1.5em;
  }

  &::placeholder {
    position: absolute;
    color: #a8a8a8;
    opacity: 1;
    top: 5px;
    left: 5px;
  }
`;

export const Textarea = styled.textarea`
  width: 100%;
  font-size: clamp(1em, 1.2vw, 1.3em);
  padding: 0.3em 0.2em;
  margin: 0.2em 0;
  border: 2px solid ${props => props.theme.colors.main};
  border-radius: 8px;
  position: relative;
  transition: all 0.1s;
  box-sizing: content-box;

  @media (max-width: 768px) {
    height: 5vh;
    font-size: 1.5em;
  }

  &::placeholder {
    position: absolute;
    top: 5px;
    left: 5px;
    font-size: 0.6em;

    @media (max-width: 768px) {
      font-size: 1.3em !important;
    }
  }
`;

export const Select = styled.select`
  width: 100%;
  font-size: clamp(1em, 1.2vw, 1.3em);
  padding: 0.4em 0.2em;
  margin: 0.2em 0;
  border: 2px solid ${props => props.theme.colors.main};
  border-radius: 8px;
  position: relative;
  transition: all 0.1s;
  box-sizing: content-box;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 98% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  @media (max-width: 768px) {
    height: 5vh;
    font-size: 1.5em;
  }
`;

export const Option = styled.option`
  font-size: 0.65em;
  font-family: ProximaNova;
`;

export const Button = styled.button`
  margin: 2vh 0 0 0;
  width: 10vw;
  padding: 0.5em 0.2em;
  background-color: ${props => props.theme.colors.main};
  border-radius: 8px;
  border: none;
  transition: all 0.3s;

  @media only screen and (max-width: 768px) {
    width: 50vw;
    height: 5vh;
    margin: 2vh 0;
  }

  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.colors.mainDark};
  }
`;
