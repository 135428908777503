export const saveSession = (token: string): void => {
  localStorage.setItem('token', token);
};

export const clearSession = (): void => {
  localStorage.removeItem('token');
};

export const getSessionToken = (): string | null => {
  const token = localStorage.getItem('token');
  if (token === 'undefined' || !token) {
    return null;
  }
  const parsedToken = JSON.parse(atob(token.split('.')[1]));
  const expirationDate = new Date(parsedToken.exp * 1000);
  const now = new Date();
  const isValid = now < expirationDate;
  if (!isValid) {
    clearSession();
    return null;
  }
  return token;
};
