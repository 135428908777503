import {
  PartnersWrapper,
  PartnersLink,
  PartnerLogo,
  // PartnersTBA,
  // PartnersTBALogo,
} from './styled';

// import kc from 'assets/partners/2KC.png';
// import rydwany from 'assets/partners/rydwany.png';
// import manekin from 'assets/partners/manekin.png';
// import jakdojade from 'assets/partners/jak_dojade.png';
import cochise from 'assets/partners/cochise.png';
import jti from 'assets/partners/jti.png';
import pg from 'assets/partners/p&g.png';
import starbucks from 'assets/partners/starbucks.png';
import zapiekarnia from 'assets/partners/zapiekarnia.png';
import trybunal from 'assets/partners/trybunal.png';
import cynamonowe from 'assets/partners/cynamonowe.png';
import lerni from 'assets/partners/lerni.png';
import forestescape from 'assets/partners/forestescape.png';
import xero from 'assets/partners/xero.png';
import nescafe from 'assets/partners/nescafe.png';
import flis from 'assets/partners/flis.png';
import miriam from 'assets/partners/miriam.png';
import movs from 'assets/partners/movs.png';
import namaste from 'assets/partners/namaste.png';
import wbc from 'assets/partners/wbc.png';
import finestra from 'assets/partners/finestra.png';

// import questionmark from 'assets/team/questionMark.png';

const PartnersList = () => {
  return (
    <PartnersWrapper>
      <PartnersLink href="https://www.jti.com/" target="_blank">
        <PartnerLogo src={jti} alt="Logo JTI" />
      </PartnersLink>
      <PartnersLink href="https://pl.pg.com" target="_blank">
        <PartnerLogo src={pg} alt="Logo P&G" />
      </PartnersLink>
      <PartnersLink href="https://www.trybunal.com.pl" target="_blank">
        <PartnerLogo src={trybunal} alt="Logo Trybunal" />
      </PartnersLink>
      <PartnersLink href="https://cochisefood.pl/" target="_blank">
        <PartnerLogo src={cochise} alt="Logo Cochise" />
      </PartnersLink>
      <PartnersLink href="https://www.starbucks.pl/" target="_blank">
        <PartnerLogo src={starbucks} alt="Logo Starbucks" />
      </PartnersLink>
      <PartnersLink
        href="https://www.facebook.com/zapiekarnia/"
        target="_blank"
      >
        <PartnerLogo src={zapiekarnia} alt="Logo Zapiekarnia" />
      </PartnersLink>
      <PartnersLink
        href="https://www.facebook.com/p/Cynamonowe-Love-61553992685823//"
        target="_blank"
      >
        <PartnerLogo src={cynamonowe} alt="Logo Cynamonowe" />
      </PartnersLink>
      <PartnersLink href="https://www.flis.pl" target="_blank">
        <PartnerLogo src={flis} alt="Logo Flis" />
      </PartnersLink>
      <PartnersLink href="https://lerni.us" target="_blank">
        <PartnerLogo src={lerni} alt="Logo Lerni" />
      </PartnersLink>
      <PartnersLink href="https://www.forestescape.pl/" target="_blank">
        <PartnerLogo src={forestescape} alt="Logo Forestescape" />
      </PartnersLink>
      <PartnersLink href="https://xerodruk.com" target="_blank">
        <PartnerLogo src={xero} alt="Logo Xero" />
      </PartnersLink>
      <PartnersLink href="https://www.nescafe.com/pl/" target="_blank">
        <PartnerLogo src={nescafe} alt="Logo Nescafe" />
      </PartnersLink>
      <PartnersLink href="https://finestra.eatbu.com/" target="_blank">
        <PartnerLogo src={finestra} alt="Logo Finestra" />
      </PartnersLink>
      <PartnersLink href="https://www.facebook.com/movstvdio" target="_blank">
        <PartnerLogo src={movs} alt="Logo MOVS" />
      </PartnersLink>
      <PartnersLink
        href="https://www.facebook.com/p/Miriam-Kawiarnia-Księgarnia-100058517594819/"
        target="_blank"
      >
        <PartnerLogo src={miriam} alt="Logo Miriam" />
      </PartnersLink>
      <PartnersLink
        href="https://www.facebook.com/puppyyogalodz"
        target="_blank"
      >
        <PartnerLogo src={namaste} alt="Logo Namaste" />
      </PartnersLink>
      <PartnersLink
        href="https://www.instagram.com/whitebearcoffeelodz/"
        target="_blank"
      >
        <PartnerLogo src={wbc} alt="Logo WBC" />
      </PartnersLink>
    </PartnersWrapper>
    // <PartnersTBA>
    //   <PartnersTBALogo src={questionmark} alt="partners soon" />
    // </PartnersTBA>
  );
};

export default PartnersList;
