import React, { useEffect, useState } from 'react';
import { IoTicketOutline as TicketIcon } from 'react-icons/io5';

import { getStatistics } from 'api/panel';
import Layout from 'components/Layout';
import {
  Bold,
  Content,
  ContentHeader,
  LoginLink,
} from 'components/Layout/styled';
import history from 'utils/history';
import RegisterForm from './RegisterForm';

import bg from 'assets/landing/landingBg.jpg';

export const Register = () => {
  const [registeredUsersCount, setRegisteredUsersCount] = useState<number>(0);
  // const [remainingSlots, setRemainingSlots] = useState<number>(0);

  useEffect(() => {
    (async () => {
      const statistics = await getStatistics();
      setRegisteredUsersCount(statistics.registrations);
      // setRemainingSlots(statistics.remainingSlots);
    })();
  }, []);

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="Rejestracja"
      green={''}
      nextLink="/"
      previousLink="/faq"
      currentPageNumber={0}
      isCenterButtonHidden
      interactiveSection={RegisterForm}
      buttonAction={() => history.push('/register')}
      isOverflowEnabled
    >
      <ContentHeader>Proces rejestracji i płatności</ContentHeader>
      <Content>
        Dołączenie do grona uczestników naszego wyjazdu wymaga od Ciebie
        wykonania 2 czynności. Na początku musisz się
        <Bold> zarejestrować i utworzyć konto</Bold>. Po otwarciu płatności
        (informacje o tym zostaną przekazane za pomocą maila), aby zapewnić
        sobie miejsce na wyjeździe należy zalogować się na to konto, przejść do
        postrony <Bold>płatności</Bold> i wysłać potwierdzenie płatności.
        <br />
        <LoginLink>
          Masz już konto?{' '}
          <span onClick={() => history.push('/login')}>kliknij tutaj.</span>
        </LoginLink>
        <br />
        Liczba rejestracji:
        <Bold style={{ fontSize: '1.5em' }}> {registeredUsersCount}</Bold>.
        {/* <br />
        Pozostało miejsc:
        <Bold style={{ fontSize: '1.5em' }}> {remainingSlots}</Bold>. */}
      </Content>
    </Layout>
  );
};
