import Layout from 'components/Layout';
import { Content } from 'components/Layout/styled';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import history from 'utils/history';

import bg from 'assets/landing/landingBg.jpg';
import Scoreboard from 'components/Admin/Content/Scoreboard';

export const ScoreboardPage = () => {
  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="PUNKTACJA"
      green={''}
      nextLink="/faq"
      previousLink="/team"
      currentPageNumber={5}
      isCenterButtonHidden
      buttonAction={() => history.push('/register')}
      isMobileReversed
      interactiveSection={() => <Scoreboard />}
    >
      <Content>
        Ranking jest na bieżąco aktualizowany przez kadrę. W przypadku
        niepoprawności wyniku, prosimy o zgłoszenie do kadry.
      </Content>
    </Layout>
  );
};
