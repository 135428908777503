import styled from 'styled-components';

export const RingDiv = styled.div`
  background-color: transparent;
  border: 2px solid #f0f4f7;
  opacity: 0.9;
  border-radius: 50%;
  animation: appear 2s 1;
  opacity: 0;
  animation-fill-mode: forwards;

  @keyframes appear {
    from {
      transform: scale(0);
      opacity: 0;
    }

    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
