import { getTeams, Team } from 'api/panel';
import firebase from 'firebase';
import { FC, useEffect, useState } from 'react';
import { TileContent, TileWrapper } from '../Tiles/Tile/styled';
import { CaptainAvatar } from './CaptainAvatar';
import { ScoreInput } from './ScoreInput';
import { ScoreBoardTilesWrapper } from './styled';

const colors = {
  turquoise: {
    backgroundColor: '#02B3A6',
    color: '#038279',
  },
  black: {
    backgroundColor: '#4a4a4a',
    color: '#333333',
  },
  blue: {
    backgroundColor: '#68BBE3',
    color: '#055C9D',
  },
  brown: {
    backgroundColor: '#D0B49F',
    color: '#9a8574',
  },
  green: {
    backgroundColor: '#76dd85',
    color: '#59a765',
  },
  mint: {
    backgroundColor: '#B5E5CF',
    color: '#8aae9e',
  },
  orange: {
    backgroundColor: '#FDB750',
    color: '#FD7F20',
  },
  pink: {
    backgroundColor: '#EC8FD0',
    color: '#D43790',
  },
  purple: {
    backgroundColor: '#C55FFC',
    color: '#7954A1',
  },
  red: {
    backgroundColor: '#FF2511',
    color: '#BA0F30',
  },
  white: {
    backgroundColor: '#FFFFFF',
    color: '#D1D1D1',
  },
  yellow: {
    backgroundColor: '#FFFF5C',
    color: '#cccc49',
  },
};

interface ScoreboardProps {
  isAdmin?: boolean;
}

const Scoreboard: FC<ScoreboardProps> = ({ isAdmin }) => {
  const [teams, setTeams] = useState<Team[]>([]);

  const handleTeamsData = async (
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    const fetchedTeams = data.docs.map(d => d.data() as Team);
    setTeams(fetchedTeams);
  };

  const sortTeamsByScore = (teams: Team[]) => {
    return isAdmin ? teams : teams.sort((a, b) => b.score - a.score);
  };

  useEffect(() => {
    const unsubscribe = getTeams(data => handleTeamsData(data));
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <ScoreBoardTilesWrapper isAdmin={!!isAdmin}>
      {sortTeamsByScore(teams).map(team => {
        return (
          <TileWrapper
            backgroundColor={colors[team.color].backgroundColor}
            color={colors[team.color].color}
            key={team.color}
          >
            <CaptainAvatar
              captainId={team.captainId.id}
              color={colors[team.color].color}
            />
            <TileContent>
              <p>{team.color}</p>
              <span>{team.score}</span>
            </TileContent>
            {isAdmin && (
              <ScoreInput teamScore={team.score} color={team.color} />
            )}
          </TileWrapper>
        );
      })}
    </ScoreBoardTilesWrapper>
  );
};

export default Scoreboard;
