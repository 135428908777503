import { Statistics } from 'api/panel';
import { InvoiceData, uploadPaymentConfirmation } from 'api/payments';
import { participantPaymentSuccess, setLoading } from 'containers/App/actions';
import { makeSelectCurrentUser } from 'containers/App/selectors';
import React, { FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Warning,
  PartyIcon,
  FeedbackMsg,
  WaitIcon,
  ClosedIcon,
  Description,
} from './styled';
import {
  Form,
  Button,
  Input,
  Label,
  Agreement,
  Invoice,
  Select,
  Option,
} from '../../Register/RegisterForm/styled';
import { useMutation } from '@apollo/client';
import { CONTACT_MUTATION } from 'api/mutations';
import {
  Container,
  Checkmark,
} from 'components/Admin/Content/Users/Filters/styled';

const FILE_SIZE_LIMIT = 4194304;

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

interface PaymentFormProps {
  statistics: Statistics | undefined;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ statistics }) => {
  const [file, setFile] = useState<File>();
  const [warning, setWarning] = useState<string>();
  const [phone, setPhone] = useState<string>('');
  const [diet, setDiet] = useState<string>('');
  const [tshirt, setTshirt] = useState<string>('');
  const [shoe, setShoe] = useState<string>('');
  const [bday, setBday] = useState<string>('');
  const [showInvoice, setShowInvoice] = useState<boolean>(false);
  const [invoicePerson, setInvoicePerson] = useState<string>('');
  const [invoiceName, setInvoiceName] = useState<string>('');
  const [invoiceSurname, setInvoiceSurname] = useState<string>('');
  const [invoiceAddress, setInvoiceAddress] = useState<string>('');
  const [pesel, setPesel] = useState<string>('');

  const { currentUser } = useSelector(stateSelector);
  const dispatch = useDispatch();

  const [submitForm] = useMutation(CONTACT_MUTATION);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setLoading(true));
    if (file) {
      let invoiceData: InvoiceData | undefined = undefined;
      if (showInvoice) {
        invoiceData = {
          name: invoicePerson === 'self' ? currentUser!.name : invoiceName,
          surname:
            invoicePerson === 'self' ? currentUser!.surname : invoiceSurname,
          address: invoiceAddress,
          pesel,
        };
      }
      await uploadPaymentConfirmation({
        file,
        diet,
        tshirt,
        shoe,
        bday,
        phone,
        year: currentUser?.year,
        invoice: invoiceData,
      });
      dispatch(participantPaymentSuccess());
      const message = `
      <h1>Witaj ${currentUser?.name}!</h1>
      <p>Otrzymaliśmy od Ciebie zgłoszenie poprzez formularz płatności. Po zweryfikowaniu przez nas wysłanego potwierdzenia zapłaty, otrzymasz informację zwrotną o zakwalifikowaniu na wyjazd.</p>
      <br/>
      <p>Pozdrawiamy
      <br/>
      <b>Kadra</b></p>`;
      await submitForm({
        variables: {
          clientMutationId: `${new Date().toUTCString()}`,
          sender: 'adapciak@samorzad.p.lodz.pl',
          topic: 'Adapciak - formularz płatności',
          email: currentUser?.email,
          message,
        },
      });
    }
    dispatch(setLoading(false));
  };

  const handleFileAddition = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      setWarning('Maksymalny dopuszczalny rozmiar pliku to 4MB');
    } else {
      setWarning(undefined);
      setFile(file);
    }
  };

  const renderContentBasedOnPool = () => {
    if (currentUser && currentUser.hasPaid) {
      return submittedFormInformation;
    } else if (statistics && statistics?.remainingSlots > 0) {
      return formBody;
    } else if (
      statistics &&
      statistics?.remainingSlots > 0 &&
      statistics.paymentStartDate.toDate() < new Date() // change paymentStartDate to TestDate if you want to test
    ) {
      return waitFormInformation;
    } else if (statistics && statistics?.remainingSlots <= 0) {
      return closeFormInformation;
    }

    return null;
  };

  const submittedFormInformation = (
    <>
      <FeedbackMsg>
        Twoje dane i potwierdzenie płatności znajdują się już w naszej bazie
        danych.
      </FeedbackMsg>
      <PartyIcon />
    </>
  );

  const waitFormInformation = (
    <>
      <FeedbackMsg>
        Płatności zostaną otwarte niebawem (nie odświeżaj strony)
      </FeedbackMsg>
      <WaitIcon />
    </>
  );

  const closeFormInformation = (
    <>
      <FeedbackMsg>Zapisy zostały zamknięte :(</FeedbackMsg>
      <ClosedIcon />
    </>
  );

  const timeFormInformation = (
    <>
      <FeedbackMsg>Zapisy zostaną otwarte wkrótce...</FeedbackMsg>
      <WaitIcon />
    </>
  );

  const isVisible =
    statistics && statistics?.paymentStartDate.toDate() < new Date(); // change paymentStartDate to TestDate if you want to test

  const formBody = (
    <>
      <h1>Uzupełnij informacje</h1>
      <Label>Rozmiar koszulki</Label>
      <Select
        value={tshirt}
        onChange={e => setTshirt(String(e.target.value))}
        required
        placeholder="Rozmiar koszulki"
      >
        <Option value="" disabled>
          Wybierz rozmiar koszulki
        </Option>
        <Option value="xs">XS</Option>
        <Option value="s">S</Option>
        <Option value="m">M</Option>
        <Option value="l">L</Option>
        <Option value="xl">XL</Option>
        <Option value="xxl">XXL</Option>
      </Select>
      <Label>Rozmiar buta</Label>
      <Input
        value={shoe}
        onChange={e => setShoe(e.target.value)}
        required
        placeholder="Rozmiar buta (30-50)"
        min="30"
        max="50"
        type="number"
      />
      <Label>Dieta</Label>
      <Select
        value={diet}
        onChange={e => setDiet(String(e.target.value))}
        required
        placeholder="Wydział"
      >
        <Option value="" disabled>
          Wybierz rodzaj diety
        </Option>
        <Option value="mieso">Dieta mięsna</Option>
        <Option value="wege">Dieta wegetariańska</Option>
        <Option value="vegan">Dieta wegańska</Option>
      </Select>
      <Label>Numer telefonu</Label>
      <Input
        value={phone}
        onChange={e => setPhone(e.target.value)}
        required
        placeholder="+48 123 456 789"
        type="tel"
        pattern="((\+48)|(\+380))?[\d]{9}"
      />
      <Label>Data urodzenia</Label>
      <Input
        value={bday}
        max="2006-08-31"
        onChange={e => setBday(e.target.value)}
        required
        placeholder="Data urodzenia"
        type="date"
      />
      <Label>Potwierdzenie płatności</Label>
      <Input
        onChange={e => e.target.files && handleFileAddition(e.target.files[0])}
        required
        placeholder="Potwierdzenie płatności"
        type="file"
        style={{ minHeight: '28px' }}
        accept=".png,.pdf,.webp,.jpg,.png,.jpeg"
      />
      <Description>
        * jeśli nie możesz wygenerować potwierdzenia, wstaw zrzut ekranu z
        aplikacji, następnie po zaksięgowaniu wyślij potwierdzenie na maila:
        adapciak@samorzad.p.lodz.pl
      </Description>
      <Invoice>
        <span>
          Czy wyrażasz chęć otrzymania faktury za przedmiotową wpłatę?
        </span>
        <Container>
          <input
            checked={showInvoice}
            onChange={e => {
              setShowInvoice(e.target.checked);
              if (!e.target.checked) {
                setInvoicePerson('');
              }
            }}
            type="checkbox"
          />
          <Checkmark />
        </Container>
      </Invoice>
      {showInvoice && (
        <Select
          onChange={e => setInvoicePerson(String(e.target.value))}
          required
          placeholder="Na kogo wystawić fakturę?"
        >
          <Option value="" disabled>
            Na kogo wystawić fakturę?
          </Option>
          <Option value="self">Na studenta</Option>
          <Option value="other">Na innego płatnika</Option>
        </Select>
      )}
      {showInvoice && invoicePerson !== '' && (
        <>
          <Label>Imię</Label>
          <Input
            value={invoicePerson === 'self' ? currentUser?.name : invoiceName}
            onChange={e => setInvoiceName(e.target.value)}
            required
            placeholder="Imię"
            type="text"
            disabled={invoicePerson === 'self' || invoicePerson === ''}
            pattern="[\p{Lu}][\p{Ll}]{2,30}"
          />
          <Label>Nazwisko</Label>
          <Input
            value={
              invoicePerson === 'self' ? currentUser?.surname : invoiceSurname
            }
            onChange={e => setInvoiceSurname(e.target.value)}
            required
            placeholder="Nazwisko"
            type="text"
            disabled={invoicePerson === 'self' || invoicePerson === ''}
            pattern="[\p{Lu}][\p{Ll}]{2,30}([-\s][\p{Lu}][\p{Ll}]{2,30})?"
          />
          <Label>Adres (ulica, kod pocztowy, miasto)</Label>
          <Input
            onChange={e => setInvoiceAddress(e.target.value)}
            value={invoiceAddress}
            required
            placeholder="Adres płatnika"
            type="text"
            pattern="[\s\p{L}\d.]{3,40}[,][\s]?[\d]{2}[-]?[\d]{3}[,][\s]?[\s\p{L}\d.]{3,40}"
          />
          <Label>Pesel</Label>
          <Input
            onChange={e => setPesel(e.target.value)}
            value={pesel}
            required
            placeholder="Pesel"
            type="text"
            pattern="[\d]{11}"
          />
          <Agreement>
            <span style={{ fontSize: 13, fontWeight: 600, color: 'red' }}>
              WAŻNE: DANE NABYWCY muszą się zgadzać z DANYMI PŁATNIKA
            </span>
          </Agreement>
        </>
      )}
      {warning && <Warning>{warning}</Warning>}
      <Agreement>
        <span>
          Akceptuję regulamin wyjazdu wyjazdu integracyjno-szkoleniowego
          "Adapciak {new Date().getFullYear()}" dostępny pod{' '}
          <a target="_blank" rel="noreferrer" href="/regulamin.pdf">
            tym adresem
          </a>{' '}
          i oświadczam, że zapoznałam / zapoznałem się z jego treścią.
        </span>
        <Container>
          <input required type="checkbox" />
          <Checkmark />
        </Container>
      </Agreement>
      <Agreement>
        <span>
          Wyrażam zgodę na przetwarzanie moich danych osobowych przez
          Politechnikę Łódzką, adres siedziby: ul. Żeromskiego 116, 90-924 Łódź,
          jako administratora, w celu zorganizowania i przeprowadzenia wyjazdu
          integracyjno-szkoleniowego "Adapciak {new Date().getFullYear()}"
          (dalej: Wyjazdu). podany w formularzu. Także zgadzam się na
          otrzymywanie wiadomości tekstowych dotyczących spraw organizacyjnych
          związanych z Wyjazdem na adres e-mail i numer telefonu podany w
          formularzu. Klauzula RODO dostępna jest{' '}
          <a target="_blank" rel="noreferrer" href="/rodo.pdf">
            tutaj
          </a>
          .
        </span>
        <Container>
          <input required type="checkbox" />
          <Checkmark />
        </Container>
      </Agreement>
      <Button disabled={!!warning} type="submit">
        Sfinalizuj
      </Button>
    </>
  );

  return (
    <Form onSubmit={(e: FormEvent) => handleFormSubmit(e)}>
      {isVisible ? renderContentBasedOnPool() : timeFormInformation}
    </Form>
  );
};

export default PaymentForm;
