import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Content from '../../../components/Admin/Content';
import Menu from 'components/Admin/Menu';
import { Bar, BarUser, MenuLink, PanelContent, Wrapper } from './styled';

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

export enum ActiveContent {
  Summary = 'Podsumowanie',
  Users = 'Uczestnicy',
  Emails = 'Wiadomości',
  Scoreboard = 'Ranking',
}

export const AdminPanel = () => {
  const { currentUser } = useSelector(stateSelector);
  const [content, setPanelContent] = useState<string>(ActiveContent.Summary);

  return (
    <Wrapper>
      <Bar>
        <BarUser>Cześć, {currentUser?.name}</BarUser>
        <MenuLink to="/">Powrót do appki</MenuLink>
      </Bar>
      <PanelContent>
        <Menu
          activeContent={content}
          setPanelContent={(value: string) => setPanelContent(value)}
        />
        <Content activeContent={content} />
      </PanelContent>
    </Wrapper>
  );
};
