import styled from 'styled-components';

export const ContentWrapper = styled.div`
  overflow-y: auto;
`;

export const Header = styled.h3`
  font-size: 4em;
  font-family: ProximaNova;
  margin: 2.5vh 0 1em;

  @media only screen and (max-width: 768px) {
    font-size: 3em;
    margin: 1.8em 0 0.7em;
  }
`;

export const Question = styled.span`
  font-family: ProximaNovaBold;
  line-height: 2em;
`;

export const IconHolder = styled.a`
  color: black;
  font-size: 5rem;
  margin: 0.25em;

  &:hover {
    color: ${props => props.theme.colors.main};
    cursor: pointer;
  }
`;
