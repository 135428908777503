import { signUpParticipant } from 'api/users';
import { participantLogout, setLoading } from 'containers/App/actions';
import React, { FormEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import history from 'utils/history';
import {
  Form,
  Input,
  Button,
  Label,
  Select,
  Option,
  Agreement,
} from './styled';
import { useMutation } from '@apollo/client';
import {
  Checkmark,
  Container,
} from 'components/Admin/Content/Users/Filters/styled';
import { CONTACT_MUTATION } from 'api/mutations';

const RegisterForm = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [surname, setSurname] = useState<string>('');
  const [year, setYear] = useState<number>(1);
  const [faculty, setFaculty] = useState<string>('');
  const [degreeCourse, setDegreeCourse] = useState<string>('');
  const dispatch = useDispatch();

  const [submitForm] = useMutation(CONTACT_MUTATION);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    dispatch(setLoading(true));
    await signUpParticipant(
      email,
      password,
      name,
      surname,
      year,
      faculty,
      degreeCourse,
    );
    const message = `
      <h1>Witaj ${name}!</h1>
      <p>Twoja rejestracja na wyjazd Adapciak została potwierdzona.</p>
      <br/>
      <h3>Twoje dane:</h3>
      <p><b>Imię:</b> ${name}</p>
      <p><b>Nazwisko:</b> ${surname}</p>
      <p><b>Email:</b> ${email}</p>
      <p><b>Rok studiów:</b> ${year}</p>
      <p><b>Wydział:</b> ${faculty}</p>
      <p><b>Kierunek studiów:</b> ${degreeCourse}</p>
      <p>Pozdrawiamy
      <br/>
      <b>Kadra</b></p>`;
    await submitForm({
      variables: {
        clientMutationId: `${new Date().toUTCString()}`,
        sender: 'adapciak@samorzad.p.lodz.pl',
        topic: 'Adapciak - rejestracja',
        email: email,
        message,
      },
    });
    dispatch(participantLogout());
    dispatch(setLoading(false));
    history.push('/');
  };

  const handleYearInput = value => {
    const numeric = Number(value);
    if (numeric < 6 && numeric > 0) setYear(numeric);
    if (numeric % 10 < 6 && numeric % 10 > 0) setYear(numeric % 10);
  };

  return (
    <Form onSubmit={(e: FormEvent) => handleFormSubmit(e)}>
      <Label>Adres email</Label>
      <Input
        onChange={e => setEmail(e.target.value)}
        required
        name="new-email"
        placeholder="Email"
        type="email"
      />
      <Label>Hasło (minimum 6 znaków)</Label>
      <Input
        onChange={e => setPassword(e.target.value)}
        required
        name="new-password"
        placeholder="Hasło"
        type="password"
      />
      <Label>Imię</Label>
      <Input
        onChange={e => setName(e.target.value)}
        required
        name="name"
        placeholder="Imię"
        type="text"
      />
      <Label>Nazwisko</Label>
      <Input
        onChange={e => setSurname(e.target.value)}
        required
        name="surname"
        placeholder="Nazwisko"
        type="text"
      />
      <Label>
        Rok studiów (na którym roku będziesz po rozpoczęciu semestru)*
      </Label>
      <Input
        onChange={e => handleYearInput(e.target.value)}
        value={year}
        required
        name="year"
        placeholder="Rok studiów"
        type="number"
        min="1"
        max="5"
      />
      <Label>Wydział</Label>
      <Select
        onChange={e => setFaculty(String(e.target.value))}
        required
        name="faculty"
        placeholder="Wydział"
      >
        <Option value="" disabled selected>
          Wybierz wydział
        </Option>
        <Option value="WM">Wydział Mechaniczny</Option>
        <Option value="WEEIA">
          Wydział Elektrotechniki, Elektroniki, Informatyki i Automatyki
        </Option>
        <Option value="WCH">Wydział Chemiczny</Option>
        <Option value="WTMITW">
          Wydział Technologii Materiałowych i Wzornictwa Tekstyliów
        </Option>
        <Option value="BINOZ">Wydział Biotechnologii i Nauk o Żywności</Option>
        <Option value="BAIS">
          Wydział Budownictwa, Architektury i Inżynierii Środowiska
        </Option>
        <Option value="FTIMS">
          Wydział Fizyki Technicznej, Informatyki i Matematyki Stosowanej
        </Option>
        <Option value="OIZ">Wydział Organizacji i Zarządzania</Option>
        <Option value="WIPOS">
          Wydział Inżynierii Procesowej i Ochrony Środowiska
        </Option>
        <Option value="IFE">Centrum Kształcenia Międzynarodowego</Option>
        <Option value="NotFromTechUni">Spoza Politechnniki Łódzkiej</Option>
      </Select>
      <Label>Kierunek studiów</Label>
      <Input
        onChange={e => setDegreeCourse(String(e.target.value))}
        required
        placeholder="Kierunek studiów"
        type="text"
      />
      <Agreement>
        <span>
          Akceptuję regulamin wyjazdu wyjazdu integracyjno-szkoleniowego
          "Adapciak {new Date().getFullYear()}" dostępny pod{' '}
          <a target="_blank" rel="noreferrer" href="/regulamin.pdf">
            tym adresem
          </a>{' '}
          i oświadczam, że zapoznałam / zapoznałem się z jego treścią.
        </span>
        <Container>
          <input required type="checkbox" />
          <Checkmark />
        </Container>
      </Agreement>
      <Agreement>
        <span>
          {' '}
          Wyrażam zgodę na przetwarzanie moich danych osobowych przez
          Politechnikę Łódzką, adres siedziby: ul. Żeromskiego 116, 90-924 Łódź,
          jako administratora, w celu zorganizowania i przeprowadzenia wyjazdu
          integracyjno-szkoleniowego "Adapciak {new Date().getFullYear()}"
          (dalej: Wyjazdu). podany w formularzu. Także zgadzam się na
          otrzymywanie wiadomości tekstowych dotyczących spraw organizacyjnych
          związanych z Wyjazdem na adres e-mail i numer telefonu podany w
          formularzu. Klauzula RODO dostępna jest{' '}
          <a target="_blank" rel="noreferrer" href="/rodo.pdf">
            tutaj
          </a>
          .
        </span>
        <Container>
          <input required type="checkbox" />
          <Checkmark />
        </Container>
      </Agreement>

      <Button type="submit">Zarejestruj się</Button>
      <br />
      <Label>
        * studia magisterskie również powinny być brane pod uwagę jako lata
        studiowania. Np. 1 semestr studiów mgr. to 4 rok studiów.
      </Label>
    </Form>
  );
};

export default RegisterForm;
