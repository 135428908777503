import { makeSelectCurrentUser } from 'containers/App/selectors';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Route } from 'react-router-dom';

interface AdminRouteProps {
  path: string;
  component: () => JSX.Element;
}

const stateSelector = createStructuredSelector({
  currentUser: makeSelectCurrentUser(),
});

const AdminRoute: React.FC<AdminRouteProps> = ({ path, component }) => {
  const { currentUser } = useSelector(stateSelector);

  return currentUser && currentUser.isAdmin ? (
    <Route exact path={path} component={component} />
  ) : null;
};

export default AdminRoute;
