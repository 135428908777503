import { resetPassword } from 'api/users';
import Layout from 'components/Layout';
import { ContentHeader } from 'components/Layout/styled';
import { setLoading } from 'containers/App/actions';
import { FormEvent, useState } from 'react';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import history from 'utils/history';

import bg from 'assets/login/reset.jpg';
import { Form, Input, Button, ErorrMsg } from './styled';

export const PasswordReset = () => {
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState<string>();
  const dispatch = useDispatch();

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      dispatch(setLoading(true));
      await resetPassword(email);
      history.push('/');
    } catch (err) {
      setError('Błąd podczas logowania...');
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="RESET HASŁA"
      green={''}
      nextLink="/"
      previousLink="/"
      currentPageNumber={999}
      isNavHidden
      buttonAction={() => history.push('/register')}
    >
      <ContentHeader>Nie martw się, zdarza się najlepszym</ContentHeader>
      <Form onSubmit={handleFormSubmit}>
        <Input
          onChange={e => setEmail(e.target.value)}
          required
          placeholder="Email"
          type="email"
        />
        {error && <ErorrMsg>{error}</ErorrMsg>}
        <Button type="submit">Zresetuj</Button>
      </Form>
    </Layout>
  );
};
