import Layout from 'components/Layout';
import { Content } from 'components/Layout/styled';
import React, { useMemo, useState } from 'react';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import history from 'utils/history';

import bg from 'assets/landing/landingBg.jpg';
import Carouselle from './Carouselle';
import {
  MemberNameHeader,
  MemberStudyInfoHeader,
  MemberInfoWrapper,
} from './styled';
import { members } from './teamMembers';

export const Team = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const activeMember = useMemo(() => {
    return members[activeIndex];
  }, [activeIndex]);

  return (
    <Layout
      background={bg}
      ButtonIcon={TicketIcon}
      header="NASZA "
      green={'KADRA'}
      nextLink="/faq" // "/scoreboard"
      previousLink="/gallery"
      currentPageNumber={4}
      isCenterButtonHidden
      isMobileReversed
      interactiveSection={() => (
        <Carouselle
          activeIndex={activeIndex}
          handleMemberChange={index => setActiveIndex(index)}
        />
      )}
      buttonAction={() => history.push('/register')}
    >
      <MemberInfoWrapper key={activeMember.name}>
        <MemberNameHeader>{activeMember.name}</MemberNameHeader>
        <MemberStudyInfoHeader>
          {activeMember.studentInfo}
        </MemberStudyInfoHeader>
        <Content>{activeMember.description}</Content>
      </MemberInfoWrapper>
    </Layout>
  );
};
