export * from './About';
export * from './Account';
export * from './Admin';
export * from './App';
export * from './Gallery';
export * from './Landing';
export * from './Login';
export * from './Partners';
export * from './PasswordReset';
export * from './Payment';
export * from './Register';
export * from './Team';
export * from './FAQ';
export * from './Scoreboard';
