import React from 'react';
import { SpinnerWrapper } from './styled';
import { ImSpinner9 as Spinner } from 'react-icons/im';

const Loader: React.FC = () => {
  return (
    <>
      <SpinnerWrapper className="unblurred">
        <Spinner />
      </SpinnerWrapper>
    </>
  );
};

export default Loader;
