import { useMutation } from '@apollo/client';
import { CONTACT_MUTATION } from 'api/mutations';
import { confirmParticipant } from 'api/users';
import { Participant } from 'api/users';
import React from 'react';
import {
  Index,
  Name,
  StatusConfirmed,
  StatusPaid,
  StatusRegistered,
  UserWrapper,
  Avatar,
  AcademicData,
  Management,
  DownloadConfirmation,
  ConfirmButton,
} from './styled';

interface UserProps {
  index: number;
  data: Participant;
}

const User: React.FC<UserProps> = ({ data, index }) => {
  const renderStatus = () => {
    if (data.hasPaid && !data.isConfirmed) {
      return <StatusPaid>Opłacone</StatusPaid>;
    } else if (data.hasPaid && data.isConfirmed) {
      return <StatusConfirmed>Potwierdzone</StatusConfirmed>;
    }
    return <StatusRegistered>Zarejestrowane</StatusRegistered>;
  };

  const [submitForm] = useMutation(CONTACT_MUTATION);

  const handleParticipantConfirmation = async (uid: string) => {
    await confirmParticipant(uid);
    const message = `
    <h1>Witaj ${data.name}!</h1>
    <p>
      Z radością informujemy, że Twoja płatność została zaakceptowana, a Twoje uczestnictwo w wyjeździe oficjalnie potwierdzone.
      Oczekuj wiadomości dotyczących harmonogramu. Widzimy się na odprawie :)
    </p>
    <br/>
    <p>Pozdrawiamy
    <br/>
    <b>Kadra</b></p>`;
    await submitForm({
      variables: {
        clientMutationId: `${new Date().toUTCString()}`,
        sender: 'adapciak@samorzad.p.lodz.pl',
        topic: 'Adapciak - potwierdzenie uczestnictwa',
        email: data.email,
        message,
      },
    });
  };

  return (
    <UserWrapper color={data.color}>
      <Index>{index}</Index>
      <Avatar url={data.avatar} />
      <Name>
        {data.name} {data.surname}
      </Name>
      <AcademicData>
        {data.year} rok, {data.faculty}, {data.degreeCourse}
      </AcademicData>
      {renderStatus()}
      <Management>
        {data.hasPaid && (
          <DownloadConfirmation target="_blank" href={data.paymentFile}>
            Plik z wpłatą
          </DownloadConfirmation>
        )}
        {data.hasPaid && !data.isConfirmed && (
          <ConfirmButton
            onClick={() => handleParticipantConfirmation(data.uid)}
          >
            Potwierdź
          </ConfirmButton>
        )}
      </Management>
    </UserWrapper>
  );
};

export default User;
