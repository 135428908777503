import { auth, firestore, increment, storage } from './firebase';
// import Mailgun from 'mailgun.js';
import { saveSession } from './session';

export interface Participant {
  uid: string;
  email: string;
  name: string;
  surname: string;
  year: number;
  hasPaid: boolean;
  isReserve: boolean;
  createdAt: Date;
  modifiedAt?: Date;
  isAdmin?: boolean;
  isConfirmed: boolean;
  faculty: string;
  degreeCourse: string;
  paymentFile?: string;
  type: number;
  avatar?: string;
  phone?: string;
  pesel?: string;
  color?: string;
}

export const logout = async (): Promise<void> => {
  await auth.signOut();
};

export const signIn = async (email: string, password: string) => {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    const token = await auth.currentUser?.getIdToken();
    saveSession(token!);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const resetPassword = async (email: string) => {
  try {
    await auth.sendPasswordResetEmail(email);
  } catch (err) {
    throw new Error(err.message);
  }
};

export const signUpParticipant = async (
  email: string,
  password: string,
  name: string,
  surname: string,
  year: number,
  faculty: string,
  degreeCourse: string,
) => {
  try {
    const response = await auth.createUserWithEmailAndPassword(email, password);
    await createParticipantDocument(
      response.user!.uid,
      email,
      name,
      surname,
      year,
      faculty,
      degreeCourse,
    );
    await firestore
      .collection('statistics')
      .doc(`config-${new Date().getFullYear()}`)
      .update({
        registrations: increment,
      });
    // Firebase will automatically login after succesfull registration. Register -> create document -> logout
    await logout();
  } catch (err) {
    throw new Error(err.message);
  }
};

export const checkIfAdmin = async (uid: string) => {
  try {
    const ref = await firestore.collection('admins').doc(uid);
    const result = await ref.get();
    if (result.exists) {
      return true;
    }
    return false;
  } catch (err) {
    throw new Error(err.message);
  }
};

const createParticipantDocument = async (
  uid: string,
  email: string,
  name: string,
  surname: string,
  year: number,
  faculty: string,
  degreeCourse: string,
) => {
  try {
    await firestore.collection('participants').doc(uid).set({
      email: email,
      hasPaid: false,
      isReserve: false,
      isConfirmed: false,
      type: 2, //2 - user, 4 - admin
      name,
      surname,
      year,
      createdAt: new Date(),
      faculty,
      degreeCourse,
    });
  } catch (err) {
    throw new Error(err.message);
  }
};

export const getUserDetails = async (uid: string): Promise<Participant> => {
  try {
    const response = await firestore.collection('participants').doc(uid).get();
    return { uid, ...response.data() } as Participant;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const deleteUser = async (uid: string) => {
  try {
    await firestore.collection('participants').doc(uid).delete();
  } catch (err) {
    throw new Error(err.message);
  }
};

export const uploadAvatar = async (file: File) => {
  try {
    const uid = await auth.currentUser!.uid;
    const path = `avatars/${uid}`;
    await storage.ref(path).put(file);
    const url = await storage.ref('avatars').child(uid).getDownloadURL();
    await firestore.collection('participants').doc(uid).update({
      avatar: url,
    });
    return url;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const confirmParticipant = async (uid: string) => {
  try {
    await firestore.collection('participants').doc(uid).update({
      isConfirmed: true,
    });
  } catch (err) {
    throw new Error(err.message);
  }
};
