import MenuEntry from 'components/Admin/MenuEntry';
import { ActiveContent } from 'containers/Admin/Panel';
import {
  MenuEmailIcon,
  MenuParticipantsIcon,
  MenuScoreboardIcon,
  MenuSummaryIcon,
  MenuWrapper,
} from './styled';

interface MenuProps {
  setPanelContent: (value: string) => void;
  activeContent: string | undefined;
}

const Menu = ({ setPanelContent, activeContent }: MenuProps) => {
  return (
    <MenuWrapper>
      <MenuEntry
        Icon={MenuSummaryIcon}
        Text={ActiveContent.Summary}
        handleClick={() => {
          setPanelContent(ActiveContent.Summary);
        }}
        activeEntryText={activeContent}
      />
      <MenuEntry
        Icon={MenuParticipantsIcon}
        Text={ActiveContent.Users}
        handleClick={() => {
          setPanelContent(ActiveContent.Users);
        }}
        activeEntryText={activeContent}
      />
      <MenuEntry
        Icon={MenuEmailIcon}
        Text={ActiveContent.Emails}
        handleClick={() => {
          setPanelContent(ActiveContent.Emails);
        }}
        activeEntryText={activeContent}
      />
      <MenuEntry
        Icon={MenuScoreboardIcon}
        Text={ActiveContent.Scoreboard}
        handleClick={() => {
          setPanelContent(ActiveContent.Scoreboard);
        }}
        activeEntryText={activeContent}
      />
    </MenuWrapper>
  );
};

export default Menu;
