import React, { useEffect, useState } from 'react';
import { SummaryWrapper } from './styled';
import { CSVLink } from 'react-csv';
import { csvProps } from '..';
import { GrFormUp as UpIcon, GrFormDown as DownIcon } from 'react-icons/gr';

interface SummaryProps {
  resultsCount: number | undefined;
  csvData: string[][] | undefined;
  checkCSVprops: CSVProps[];
  setCheckCSVprops: React.Dispatch<React.SetStateAction<CSVProps[]>>;
  reload: () => void;
}

export interface CSVProps {
  value: string;
  title: string;
}

const Summary = ({
  csvData,
  resultsCount,
  checkCSVprops,
  setCheckCSVprops,
  reload,
}: SummaryProps) => {
  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    p: CSVProps,
  ) => {
    if (e.target.checked) {
      setCheckCSVprops([
        ...checkCSVprops,
        { value: e.target.value, title: p.title },
      ]);
    } else {
      const updatedCheckCSVprops = [...checkCSVprops];
      const index = updatedCheckCSVprops.findIndex(
        v => v.value === e.target.value,
      );
      updatedCheckCSVprops.splice(index, 1);
      setCheckCSVprops(updatedCheckCSVprops);
    }
  };

  const [isLayerShown, setIsLayerShown] = useState<boolean>(false);

  const showLayer = () => {
    setIsLayerShown(current => !current);
  };

  useEffect(() => {
    reload();
  }, [checkCSVprops]);

  return (
    <SummaryWrapper isLayerShown={isLayerShown}>
      <div className="summaryLabel">
        <span>Wyniki: {resultsCount}</span>
        <button onClick={showLayer}>
          {isLayerShown ? <DownIcon /> : <UpIcon />}
        </button>
        {csvData && csvData.length > 0 && (
          <CSVLink data={csvData}>Pobierz</CSVLink>
        )}
      </div>
      <div className="csvProps">
        Opcje CSV:
        <ul>
          {csvProps.map(p => (
            <li key={p.value}>
              <input
                type="checkbox"
                name={p.value}
                id={p.value}
                value={p.value}
                defaultChecked
                onChange={e => handleCheckboxChange(e, p)}
              />
              <label htmlFor={p.value}>{p.title}</label>
            </li>
          ))}
        </ul>
      </div>
    </SummaryWrapper>
  );
};

export default Summary;
