import React from 'react';

import { members } from '../teamMembers';

import { CarouselleWrapper } from './styled';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCoverflow } from 'swiper';
import CarouselleItem from './CarouselleItem';

interface CarouselleProps {
  handleMemberChange: (index: number) => void;
  activeIndex: number;
}

const SLIDES_PER_VIEW = 3;

const Carouselle: React.FC<CarouselleProps> = ({
  handleMemberChange,
  activeIndex,
}) => {
  return (
    <CarouselleWrapper>
      <Swiper
        slideToClickedSlide={true}
        centeredSlides={true}
        slidesPerView={SLIDES_PER_VIEW}
        spaceBetween={0}
        loop={true}
        grabCursor={true}
        modules={[EffectCoverflow]}
        effect={'coverflow'}
        coverflowEffect={{
          rotate: 0,
          stretch: 100,
          depth: 264,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        initialSlide={0}
        onSlideChange={swiper => handleMemberChange(swiper.realIndex)}
      >
        {members.map((member, index) => (
          <SwiperSlide key={member.name}>
            <CarouselleItem isActive={activeIndex === index} item={member} />
          </SwiperSlide>
        ))}
      </Swiper>
    </CarouselleWrapper>
  );
};

export default Carouselle;
