import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #22292f;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Bar = styled.div`
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const BarUser = styled.p`
  font-size: 3rem;
  font-family: ProximaNovaBold;
  margin: 0 1em 0 0;
  color: ${props => props.theme.colors.white};

  @media (max-width: 768px) {
    font-size: 3em;
  }
`;

export const PanelContent = styled.div`
  width: 100%;
  display: flex;
  height: 93vh;
`;

export const MenuLink = styled(Link)`
  margin: 0 1em;
  font-size: 3rem;
  font-family: ProximaNovaBold;
  color: ${props => props.theme.colors.main};

  @media (max-width: 768px) {
    font-size: 3em;
  }
`;
