import firebase from 'firebase';
import _ from 'lodash';
import { firestore } from './firebase';
import { Participant } from './users';

export type Statistics = {
  firstTicketPool: number;
  secondTicketPool: number;
  registrations: number;
  remainingSlots: number;
  paymentStartDate: firebase.firestore.Timestamp;
  TestDate: firebase.firestore.Timestamp;
};

export type Team = {
  color: string;
  score: number;
  captainId: {
    id: string;
    path: string;
  };
};

export enum Filters {
  REGISTERED_ONLY = 'registeredOnly',
  PAID = 'paid',
  CONFIRMED = 'confirmed',
}

export const getUsersInRealtime = (
  onChange: (
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => void,
) => {
  try {
    const unsubscribe = firestore
      .collection('participants')
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getUsersWithFilters = (
  filters: Filters[],
  onChange: (participants: Participant[]) => void,
) => {
  let subscriptions = [];

  const handleChange =
    () =>
    (
      participants: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
    ) => {
      let transformedUsers: Participant[] = [];
      participants.docs.forEach(d => {
        transformedUsers.push({ ...d.data(), uid: d.id } as Participant);
      });
      onChange(transformedUsers);
    };

  subscriptions = subscriptions.concat(getRegisteredUsers(handleChange()));

  if (_.includes(filters, Filters.REGISTERED_ONLY)) {
    subscriptions = subscriptions.concat(
      getUsersWithoutPayment(handleChange()),
    );
  }

  if (_.includes(filters, Filters.PAID)) {
    subscriptions = subscriptions.concat(getPaidUsers(handleChange()));
  }

  if (_.includes(filters, Filters.CONFIRMED)) {
    subscriptions = subscriptions.concat(getConfirmedUsers(handleChange()));
  }

  const removeAllSubscription = () => {
    subscriptions.forEach((subscription: any) => subscription());
  };

  return removeAllSubscription;
};

export const getRegisteredUsers = (onChange): any => {
  try {
    const unsubscribe = firestore
      .collection('participants')
      .where('type', '==', 2)
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getUsersWithoutPayment = (onChange): any => {
  try {
    const unsubscribe = firestore
      .collection('participants')
      .where('type', '==', 2)
      .where('hasPaid', '==', false)
      .where('isConfirmed', '==', false)
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getPaidUsers = (onChange): any => {
  try {
    const unsubscribe = firestore
      .collection('participants')
      .where('type', '==', 2)
      .where('hasPaid', '==', true)
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getConfirmedUsers = (onChange): any => {
  try {
    const unsubscribe = firestore
      .collection('participants')
      .where('type', '==', 2)
      .where('isConfirmed', '==', true)
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getStatistics = async () => {
  try {
    const response = firestore.collection('statistics');
    const data = await response.get();
    const parsedData = data.docs
      .filter(doc => doc.id.includes(new Date().getFullYear().toString()))
      .map(doc => doc.data())
      .pop();
    return parsedData as Statistics;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const getTeams = (
  onChange: (
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => void,
) => {
  try {
    const unsubscribe = firestore
      .collection('teams')
      .onSnapshot(function (querySnapshot) {
        onChange(querySnapshot);
      });
    return unsubscribe;
  } catch (err) {
    throw new Error((err as any).message);
  }
};

export const updateTeamScore = async ({
  color,
  score,
}: {
  color: string;
  score: number;
}) => {
  try {
    const response = firestore.collection('teams').doc(color);
    await response.update({ score: score });
  } catch (err) {
    throw new Error((err as any).message);
  }
};
