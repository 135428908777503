import { EmailsWrapper, Header } from './styled';
import { useEffect, useState } from 'react';
import { getUsersInRealtime } from 'api/panel';
import firebase from 'firebase';
import { Participant } from 'api/users';
import { USER_TYPE } from 'components/Admin/types';
import {
  Form,
  Label,
  Select,
  Textarea,
  Option,
  Input,
} from 'containers/Register/RegisterForm/styled';
import { Button } from 'containers/Payment/PaymentForm/styled';
import { useDispatch } from 'react-redux';
import { setLoading } from 'containers/App/actions';
import { useMutation } from '@apollo/client';
import { MULTIPLE_CONTACT_MUTATION } from 'api/mutations';

const Emails = () => {
  const [users, setUsers] = useState<Participant[]>([]);
  const [message, setMessage] = useState<string>(
    `<h1>Cześć!</h1>
<p>Informacje</p>
<br/>
<p>Pozdrawiamy
<br/>
<b>Kadra</b>
</p>`,
  );
  const [target, setTarget] = useState<string>('');
  const [topic, setTopic] = useState<string>('');

  const dispatch = useDispatch();

  const handleUsersData = async (
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    const newUsers: Participant[] = [];
    data.docs.forEach(d => newUsers.push(d.data() as Participant));
    setUsers(newUsers);
  };

  useEffect(() => {
    const unsubscribe = getUsersInRealtime(data => handleUsersData(data));

    return () => {
      unsubscribe();
    };
  }, []);

  const [submitForm] = useMutation(MULTIPLE_CONTACT_MUTATION);

  const handleFormSubmit = async e => {
    let usersToNotify: Participant[] = [];
    e.preventDefault();
    dispatch(setLoading(true));
    let predicate: (p: Participant) => boolean = () => true;

    switch (target) {
      case 'all': {
        predicate = p => p.name !== undefined;
        break;
      }
      case 'registered': {
        predicate = p => p.type === USER_TYPE.USER && p.hasPaid === false;
        break;
      }
      case 'paid': {
        predicate = p => p.type === USER_TYPE.USER && p.hasPaid === true;
        break;
      }
      case 'confirmed': {
        predicate = p =>
          p.type === USER_TYPE.USER && p.hasPaid === true && p.isConfirmed;
        break;
      }
    }
    usersToNotify = [...users].filter(predicate);
    const emailsToSend = usersToNotify.map(p => p.email);

    submitForm({
      variables: {
        clientMutationId: `${new Date().toUTCString()}`,
        sender: 'adapciak@samorzad.p.lodz.pl',
        topic: topic,
        emails: emailsToSend,
        message,
      },
    });

    setTopic('');
    setMessage('');
    dispatch(setLoading(false));
  };

  return (
    <EmailsWrapper>
      <Header>Panel wysyłania wiadomości email</Header>
      <Form onSubmit={e => handleFormSubmit(e)}>
        <Label>Grupa odbiorców</Label>
        <Select
          onChange={e => setTarget(String(e.target.value))}
          required
          placeholder="Do kogo wysłać wiadomość?"
          defaultValue=""
        >
          <Option value="" disabled>
            Do kogo wysłać wiadomość?
          </Option>
          <Option value="all">Wszyscy</Option>
          <Option value="registered">Zarejestrowani</Option>
          <Option value="paid">Opłaceni</Option>
          <Option value="confirmed">Zakwalifikowani</Option>
        </Select>
        <Label>Temat</Label>
        <Input
          onChange={e => setTopic(e.target.value)}
          required
          placeholder="Temat"
          type="text"
          value={topic}
        />
        <Label>Wiadomość (w HTML)</Label>
        <Textarea
          onChange={e => setMessage(e.target.value)}
          value={message}
          required
          placeholder="Wiadomość email (w HTML)"
          cols={100}
          rows={12}
        />
        <Button type="submit">Wyślij wiadomości</Button>
      </Form>
    </EmailsWrapper>
  );
};

export default Emails;
