import styled from 'styled-components';
import { BsListTask, BsEnvelope } from 'react-icons/bs';
import { FaUsers } from 'react-icons/fa';
import { GrUserAdmin, GrScorecard } from 'react-icons/gr';

export const MenuWrapper = styled.div`
  width: 10vw;
  padding: 0.5vw;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  flex-direction: column;
`;

const MenuIconStyle = `
  width: 1.6vw;
  height: 1.6vw;
`;

export const MenuSummaryIcon = styled(BsListTask)`
  ${MenuIconStyle} {
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export const MenuEmailIcon = styled(BsEnvelope)`
  ${MenuIconStyle} {
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export const MenuParticipantsIcon = styled(FaUsers)`
  ${MenuIconStyle} {
    path {
      fill: ${props => props.theme.colors.white};
    }
  }
`;

export const MenuAdminsIcon = styled(GrUserAdmin)`
  ${MenuIconStyle} {
    path {
      stroke: ${props => props.theme.colors.white};
    }
  }
`;

export const MenuScoreboardIcon = styled(GrScorecard)`
  ${MenuIconStyle} {
    path {
      stroke: ${props => props.theme.colors.white};
    }
  }
`;
