import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyANXP5buNFtff_Zajm95XOQFgDELTG3Nyo',
  authDomain: 'adapciak-af89e.firebaseapp.com',
  databaseURL:
    'https://adapciak-af89e-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'adapciak-af89e',
  storageBucket: 'adapciak-af89e.appspot.com',
  messagingSenderId: '178862329242',
  appId: '1:178862329242:web:a39efd1611749c7e0bbe23',
  measurementId: 'G-J0MQRHZ6LT',
};

firebase.initializeApp(firebaseConfig);

// Initialize real time database
export const database = firebase.database();
export const firestore = firebase.firestore();
export const auth = firebase.auth();
export const provider = new firebase.auth.GoogleAuthProvider();
export const storage = firebase.storage();

export const increment = firebase.firestore.FieldValue.increment(1);
export const decrement = firebase.firestore.FieldValue.increment(-1);
