import Tile from './Tile';
import { TilesWrapper } from './styled';
import { BiWallet } from 'react-icons/bi';
import { FaUsers, FaUserPlus, FaBaby } from 'react-icons/fa';
import { RiAdminFill as Admin, RiGroupLine } from 'react-icons/ri';
import { IoMdSchool } from 'react-icons/io';
import { MdAttachMoney as Money } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { getUsersInRealtime } from 'api/panel';
import firebase from 'firebase';
import { Participant } from 'api/users';
import { USER_TYPE } from 'components/Admin/types';

const PRICE: Readonly<number> = 999;

const Tiles = () => {
  const [registeredUsersCount, setRegisteredUsersCount] = useState<number>(0);
  const [paidUsersCount, setPaidUsersCount] = useState<number>(0);
  const [confirmedUsersCount, setConfirmedUsersCount] = useState<number>(0);
  const [youngerUsersCount, setYoungerUsersCount] = useState<number>(0);
  const [youngerPaidCount, setYoungerPaidUsersCount] = useState<number>(0);
  const [mostOccuringYear, setMostOccuringYear] = useState<number>();
  const [mostOccuringFaculty, setMostOccuringFaculty] = useState<string>();
  const [adminsCount, setAdminsCount] = useState<number>(0);

  const handleUsersData = async (
    data: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>,
  ) => {
    const users: Participant[] = [];
    data.docs.forEach(d => users.push(d.data() as Participant));
    setAdminsCount(users.filter(u => u.type === USER_TYPE.ADMIN).length);
    setRegisteredUsersCount(
      users.filter(u => u.type === USER_TYPE.USER).length,
    );
    setPaidUsersCount(
      users.filter(u => u.hasPaid === true && u.type === USER_TYPE.USER).length,
    );
    setConfirmedUsersCount(
      users.filter(u => u.isConfirmed === true && u.type === USER_TYPE.USER)
        .length,
    );
    setYoungerUsersCount(
      users.filter(u => u.year < 2 && u.type === USER_TYPE.USER).length,
    );
    setYoungerPaidUsersCount(
      users.filter(u => u.year < 2 && u.type === USER_TYPE.USER && u.hasPaid)
        .length,
    );
    setMostOccuringYear(
      users
        .filter(u => u.type === USER_TYPE.USER)
        .sort(
          (a, b) =>
            users.filter(v => v.year === a.year).length -
            users.filter(v => v.year === b.year).length,
        )
        .pop()?.year,
    );
    setMostOccuringFaculty(
      users
        .filter(u => u.type === USER_TYPE.USER)
        .sort(
          (a, b) =>
            users.filter(v => v.faculty === a.faculty).length -
            users.filter(v => v.faculty === b.faculty).length,
        )
        .pop()?.faculty,
    );
  };

  useEffect(() => {
    const unsubscribe = getUsersInRealtime(data => handleUsersData(data));

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <TilesWrapper>
      <Tile
        backgroundColor={'#eff8ff'}
        color={'#2779bd'}
        Icon={FaUserPlus}
        title={'rejestracje'}
        text={registeredUsersCount.toString()}
      />

      <Tile
        backgroundColor={'#e3fcec'}
        color={'#1f9d55'}
        Icon={FaBaby}
        title={'bejbiki zarejestrowane'}
        text={youngerUsersCount.toString()}
      />
      <Tile
        backgroundColor={'#e6e8ff'}
        color={'#5661b3'}
        Icon={FaUsers}
        title={'potwierdzeni uczestnicy'}
        text={confirmedUsersCount.toString()}
      />
      <Tile
        backgroundColor={'#fcfbec'}
        color={'#f2d027'}
        Icon={Money}
        title={'liczba wpłat'}
        text={paidUsersCount.toString()}
      />
      <Tile
        backgroundColor={'#fff5eb'}
        color={'#de751f'}
        Icon={FaBaby}
        title={'bejbiki opłacone'}
        text={youngerPaidCount.toString()}
      />
      <Tile
        backgroundColor={'#fcebea'}
        color={'#cc1f1a'}
        Icon={BiWallet}
        title={'pieniądze z wpłat'}
        text={`${(paidUsersCount * PRICE).toString()} zł`}
      />
      <Tile
        backgroundColor={'#eeeeee'}
        color={'#5e5e5e'}
        Icon={RiGroupLine}
        title={'najczęściej występujący rok studiów (rejestracje)'}
        text={mostOccuringYear?.toString() ?? '0'}
      />
      <Tile
        backgroundColor={'#eeeeee'}
        color={'#5e5e5e'}
        Icon={IoMdSchool}
        title={'najczęściej występujący wydział (rejestracje)'}
        text={mostOccuringFaculty ?? '0'}
      />
      <Tile
        backgroundColor={'#eeeeee'}
        color={'#5e5e5e'}
        Icon={Admin}
        title={'administracja'}
        text={adminsCount.toString()}
      />
    </TilesWrapper>
  );
};

export default Tiles;
