import { IconType } from 'react-icons/lib';
import theme from 'theme/theme';
import { MenuEntryDescription, MenuEntryWrapper } from './styled';

interface MenuEntryProps {
  Icon: IconType;
  Text: string;
  handleClick: () => void;
  activeEntryText: string | undefined;
}

const MenuEntry: React.FC<MenuEntryProps> = ({
  Icon,
  Text,
  handleClick,
  activeEntryText,
}) => {
  const EntryColor =
    Text === activeEntryText ? theme.colors.mainDark : theme.colors.white;

  return (
    <MenuEntryWrapper onClick={handleClick} restColor={EntryColor}>
      <Icon />
      <MenuEntryDescription restColor={EntryColor}>{Text}</MenuEntryDescription>
    </MenuEntryWrapper>
  );
};

export default MenuEntry;
