import React, { useEffect, useState } from 'react';
import { CounterWrapper } from './styled';

const SECOND: Readonly<number> = 1000;
const MINUTE: Readonly<number> = 60000;
const HOUR: Readonly<number> = 3600000;
const DAY: Readonly<number> = 86400000;
const EVENT_TIME: Readonly<number> = Date.parse('19 Sept 2022 05:00:00');

const Counter = () => {
  let currentTime = Date.now();
  let diff = EVENT_TIME - currentTime;
  let [days, setDays] = useState(0);
  let [hours, setHours] = useState(0);
  let [minutes, setMinutes] = useState(0);
  let [seconds, setSeconds] = useState(0);

  const refreshCounter = () => {
    setDays(Math.floor(diff / DAY));
    setHours(Math.floor((diff % DAY) / HOUR));
    setMinutes(Math.floor((diff % HOUR) / MINUTE));
    setSeconds(Math.floor((diff % MINUTE) / SECOND));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshCounter();
    }, 1000);
    return () => clearInterval(interval);
  });

  if (diff >= 0) {
    return (
      <CounterWrapper>
        <h1>Do Adapciaka pozostało:</h1>
        <ul>
          <li>
            <p>{days}</p>
            <span>dni</span>
          </li>
          <li>
            <p>{hours}</p>
            <span>godz.</span>
          </li>
          <li>
            <p>{minutes}</p>
            <span>min.</span>
          </li>
          <li>
            <p>{seconds}</p>
            <span>sek.</span>
          </li>
        </ul>
      </CounterWrapper>
    );
  } else {
    return (
      <CounterWrapper>
        <h1>Wydarzenie już wkrótce!</h1>
      </CounterWrapper>
    );
  }
};

export default Counter;
