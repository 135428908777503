import styled from 'styled-components';

export const CounterWrapper = styled.div`
  font-family: ProximaNova;

  h1 {
    font-size: clamp(2rem, 2vw, 4rem);
  }

  & ul {
    list-style-type: none;
    display: flex;
    font-size: clamp(2rem, 2vw, 4rem);
    & li {
      text-align: center;
      width: 25%;
      line-height: 1;
      font-weight: bolder;
      margin-bottom: 25px;
      & span {
        font-size: 2rem;
        font-weight: normal;
      }
      & p {
        margin: 0 auto;
        padding-top: 3px;
        padding-bottom: 3px;
        background-color: ${props => props.theme.colors.main};
        border-radius: 3px;
        width: 75%;
        border: none;
      }
    }
  }
`;
