import styled from 'styled-components';

const missingAvatar =
  'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/1024px-Missing_avatar.svg.png';

export const AvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 90px;
  margin: 3vw;
  width: 40%;

  @media (min-width: 768px) {
    margin: 1rem;
  }
`;

export const Avatar = styled.div<{ url: string | undefined; color: string }>`
  background-color: #bfbfbd;
  border-radius: 50%;
  border: 2px solid ${props => props.color};
  width: 25vw;
  height: 25vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${props => props.url ?? missingAvatar});

  @media (min-width: 768px) {
    width: 8rem;
    height: 8rem;
  }
`;
