import styled from 'styled-components';

export const ItemWrapper = styled.div`
  width: 100%;
`;

export const MemberPhoto = styled.img`
  width: 100%;
  object-fit: cover;
  box-shadow: 0px 13px 57px #00000070;
`;

export const MemberName = styled.h1<{ isActive: boolean }>`
  font-size: 4em;
  margin: 1em 0;
  text-align: center;
  font-family: BalooChettan2ExtraBold;
  text-transform: uppercase;
  white-space: nowrap;
  opacity: ${props => (props.isActive ? 1 : 0)};
  transition: all 0.3s;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MemberRole = styled.h2<{ isActive: boolean }>`
  margin: 1em 0 0 0;
  font-size: 3em;
  text-align: center;
  font-family: BalooChettan2ExtraBold;
  text-transform: uppercase;
  opacity: ${props => (props.isActive ? 1 : 0)};
  position: absolute;
  width: 100%;
  transition: all 0.3s;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
