import { NavigationWrapper, PagesIndicator } from './styled';
import { GrFormPreviousLink as PreviousIcon } from 'react-icons/gr';
import { GrFormNextLink as NextIcon } from 'react-icons/gr';
import { Link } from 'react-router-dom';
import useEventListener from 'hooks/useEventListener';
import history from 'utils/history';

interface NavigationProps {
  previousLink: string;
  nextLink: string;
  activePageNumber: number;
}

const NEXT_KEY = 39;
const PREVIOUS_KEY = 37;

const Navigation: React.FC<NavigationProps> = ({
  previousLink,
  nextLink,
  activePageNumber,
}) => {
  const handleSubpageChange = (value: number) => {
    if (value) {
      history.push(nextLink);
    } else {
      history.push(previousLink);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === NEXT_KEY) {
      handleSubpageChange(+1);
    } else if (e.keyCode === PREVIOUS_KEY) {
      handleSubpageChange(0);
    }
  };

  useEventListener('keydown', handleKeyDown);

  return (
    <NavigationWrapper>
      <Link to={previousLink}>
        <PreviousIcon />
      </Link>
      <PagesIndicator>
        <span>0{activePageNumber}</span>
        <span>/</span>
        <span>06</span>
      </PagesIndicator>
      <Link to={nextLink}>
        <NextIcon />
      </Link>
    </NavigationWrapper>
  );
};

export default Navigation;
