import { signIn } from 'api/users';
import Layout from 'components/Layout';
import { ContentHeader } from 'components/Layout/styled';
import React, { FormEvent, useState } from 'react';

import { IoTicketOutline as TicketIcon } from 'react-icons/io5';
import history from 'utils/history';

import bg from 'assets/login/admin.jpg';
import { Form, Input, Button } from './styled';

export const Admin = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      await signIn(email, password);
      history.push('/admin-panel');
    } catch (err) {
      console.log('Błąd podczas logowania...');
    }
  };

  return (
    <Layout
      background={bg}
      smallerHeader=""
      ButtonIcon={TicketIcon}
      header="Admin "
      green={'panel'}
      nextLink="/"
      previousLink="/"
      currentPageNumber={999}
      isNavHidden
      isCenterButtonHidden
      buttonAction={() => history.push('/register')}
    >
      <ContentHeader>Zaloguj się</ContentHeader>
      <Form onSubmit={(e: FormEvent) => handleFormSubmit(e)}>
        <Input
          onChange={e => setEmail(e.target.value)}
          required
          placeholder="Email"
          type="email"
        />
        <Input
          onChange={e => setPassword(e.target.value)}
          required
          placeholder="Hasło"
          type="password"
        />
        <Button type="submit">Zaloguj się</Button>
      </Form>
    </Layout>
  );
};
